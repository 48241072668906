.nameAvtar{
    height: 50px;
    width: 50px;
    background-color: #000;
    color: #fff;
    font-size: 16px;
    column-gap: 5px;
    align-items: center;
    justify-content: center;
    display: inline-flex;
    border-radius: 50%;
    font-weight: bold;
    padding: 3px;
    text-transform: uppercase;

    &.small{
        height: 25px;
        width: 25px;
        column-gap: 1px;
        font-size: 10px;
    }
}