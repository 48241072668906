.lemfont-bold {
  font-family: "Lemfont - bold - good one", sans-serif;
}
.bold {
  font-weight: 700 !important;
  font-size: 2.5em;
}

.sales_section {
  background: #fff;
  padding: 20px;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 2px #cccccc40 !important;
  border: 1px solid #e9e9e9;
}
.sales-sect-bg {
  background: #fff;
  border-radius: 12px;
  width: 100%;
  height: 100%;
  box-shadow: 0px 0px 2px #cccccc40 !important;
  border: 1px solid #e9e9e9;
}
.sales-sect-bg .sales_headings {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  background: #00208c0f;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
}
.value-parah {
  padding: 15px;
}
span.grenn_persentage {
  font-size: 15px;
  margin-left: 10px;
  color: #1ad01a;
}
.sales_headings {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}
h3.Amount_sales {
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 0px !important;
}
p.sub_heading {
  font-size: 16px;
  font-weight: 500;
  color: #202020;
  margin-bottom: 13px;
}
img.wallets_img {
  width: 24px;
  height: 24px;
}

.canvasjs-chart-container {
  .canvasjs-chart-credit {
    display: none;
  }
}

.highcharts-credits {
  display: none;
}

@keyframes glowPulse {
  0% {
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.3));
  }
  50% {
    filter: drop-shadow(0px 0px 20px rgba(255, 255, 255, 0.6));
  }
  100% {
    filter: drop-shadow(0px 0px 10px rgba(255, 255, 255, 0.3));
  }
}

.glow-pie {
  animation: glowPulse 2s infinite alternate ease-in-out;
}
