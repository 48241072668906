@import '../../../scss/mixins';

.main-sidebar .nav .nav-item.active>.nav-link {
    background-color: #fff;
    color: #707070;
    font-size: 14px;
    font-weight: 500;


}

.btn:hover {
    color: #ffffff;
}

.hoverclass:hover {
    background: #D7F5FF;
    color: #197D9F !important;
    font-size: 14px;
    font-weight: 500;
}

.subMenuDropdown {
    padding-left: 14px;
}

.main-sidebar .nav .nav-item .nav-link {
    color: #202020 !important;
    font-size: 16px;
    font-weight: 300;
    height: 40px;
    margin: 3px 0px;
    display: flex;
    // margin: 3px 13px;
    border-radius: 12px;
    align-items: center;

    & i {
        color: #898989;
        font-size: 18px;
    }

    & i.sidenv {
        // display: none;
    }


}

.main-sidebar .nav .nav-item>.nav-link:hover,
.main-sidebar .nav .nav-item.active>.nav-link {
    color: #705EE6 !important;
    background: #EDECF9;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    height: 40px;
    align-items: center;
    margin: 3px 0px;
    // margin-right: 10px !important;
    border-radius: 0px 20px 20px 0px;
    transition: 0.2s;
    // width: 75%;

    & i {
        color: #00218F;
    }



}

.editLogo img {
    max-width: 155px !important;
}

a.col_side {
    color: #202020 !important;
    font-size: 16px;
    font-weight: 300;
    height: 40px;
    display: flex;
    /* margin: 3px 14px; */
    border-radius: 12px;
    align-items: center;
    padding: 5px 30px 0px 5px;
    /* padding: 0px 15px; */
    justify-content: space-between;
    /* width: auto; */
}

a.col_side:hover {
    color: #705EE6 !important;
    background: #EDECF9;
    font-size: 16px;
    font-weight: 300;
    display: flex;
    height: 40px;
    // margin: 3px 14px;
    border-radius: 12px;
    align-items: center;
    // padding: 0px 15px;
    text-decoration: none;

    & i {
        color: #898989;
    }

    &.card.card-body.sides {
        border: none;
        visibility: hidden;
    }
}

.logocls {
    width: 100%;

    margin-left: 10px;
}

// .main-sidebar:hover {
//     width: 186px !important;
// }


.hoverclass {
    &.nobg {
        background: none !important;
    }

}

.submenu {
    display: none;
}

.active>.submenu {
    display: block;
}

.submenu>*>.hoverclass {
    padding-left: 25px;


}

.submenu.child2>*>.hoverclass {
    padding-left: 35px;
}

span.side_head {
    font-size: 16px;
    font-weight: 300;
    line-height: 20px;
}

.card.card-body.sides {
    // margin:6px;
    padding: 0px 0px;
}

.card.card-body.sides li.nav-item.active a span {
    color: #765aa6 !important;
    font-weight: 300 !important;
}

.card.card-body.sides li a {
    border-radius: 4px !important;
    padding-left: 25px;
}

.card.card-body.sides {
    border: none;
}

.card.card-body.sides li a span {
    color: #5A5A5A !important;
    white-space: nowrap;
    // font-weight: 100;
    font-weight: 400;
    font-size: 16px;
    max-width: 182px;
}

span.side_icon {
    position: relative;
    right: -10px;
    top: 3px;
    color: #898989;
}

// .col_side i {
//     padding: 20px;
//     display: block;
//     font-size: 100px;
//     transition: all 0.4s ease;
//   }

//   .col_side :active i {
//     transform: rotateZ(-90deg);
//   }
.col_side div i.material-icons.mr-2 {
    color: #765aa6;
    font-size: 18px;

}

.collapsed div i.material-icons.mr-2 {
    color: #898989 !important;
    font-size: 18px;
}

a.col_side.collapsess i.material-icons.mr-2 {
    color: #765aa6 !important;

}

a.col_side.collapsess.collapsed i.material-icons.mr-2 {
    color: #898989 !important;
    font-size: 18px;

}

// li.active_li{
//     position: absolute;
//     bottom: 0;
// }

// .material-icons {
//     color: rgb(78, 173, 204);
// }

.tooltip {
    position: relative;
    display: inline-block;
    border-bottom: 1px dotted black;
}
.expiry
{
    color: red($color: #dd1111);
}

.tooltip .tooltiptext {
    visibility: hidden;
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;

    /* Position the tooltip */
    position: absolute;
    z-index: 1;
}

.tooltip:hover .tooltiptext {
    visibility: visible;
}

//   .aclass
//   {
//     color
//   }


.scrollbar_add {
    overflow-y: auto;
    height: 200px;
}

.teams_people_scroll {
    overflow-y: auto;
    height: 160px;
    overflow-x: hidden;
}



/* ===== Scrollbar CSS ===== */
/* Firefox */
* {
    scrollbar-width: thin;
    scrollbar-color: #0f149f #ffffff;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
    width: 10px;
}

*::-webkit-scrollbar-track {
    background: #ffffff;
}

*::-webkit-scrollbar-thumb {
    background-color: #0f149f;
    border-radius: 10px;
    border: 3px solid #ffffff;
}