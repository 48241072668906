.edit-profile-view img {
  width: 45px;
  height: 45px;
  object-fit: cover;
  border-radius: 50%;
}
.creation-date {
  font-size: 12px;
  color: #7e7777;
}
.team-head {
  font-size: 21px;
  margin: 0;
  line-height: 27px;
}
.main-view-page label {
  font-size: 13px;
}
.main-view-page label {
  font-size: 13px;
}
.head-view {
  border-bottom: 1px solid #efefef;
  padding: 16px 20px;
}
.description-detail {
  padding: 10px 20px;
}
.dropdowns_custom .dropdown-item {
  font-size: 12px;
}
.dropdowns_custom .dropdown-item i {
  width: 14px;
}
.description-detail p {
  font-size: 14px;
  line-height: 26px;
  font-weight: 600;
}
.edit-profile-view i {
  right: 0;
  position: absolute;
  bottom: 1px;
  color: #afa2a2;
  background: #ffffff;
  padding: 4px;
  border-radius: 50%;
  font-size: 8px;
  border: 1px solid #efefef;
}
input.form-control.field-change {
  border: 0;
  padding: 0;
  height: 20px;
}
.input-field-new {
  position: relative;
  display: flex;
  align-items: center;
}
.input-field-new span {
  font-size: 8px;
}

.mainarea {
  overflow-x: unset !important;
}

.value-title {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.Amount_sales {
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
}

.move-left {
  margin-left: 100px !important;
}

.headertopteams {
  margin-bottom: -27px !important;
  margin-left: 22px;
}

.three-corners {
  border-radius: 0px 8px 8px 8px;
}

.btn-team {
  color: #000000 !important;
  background-color: #ffffff !important;
  border-color: #ffffff !important;
}

button#CloseCreateTeamModal {
  margin-right: 10px;
}
