$primary-color: #004aad;
$secondary-color: #008000;
$bg-light: #e8f0ff;
$card-bg: #ffffff;
$text-dark: #222;
$text-light: #666;
$border-radius: 12px;
$box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);

.containerROI {
  background-color: $bg-light;
  padding: 20px;
  border-radius: $border-radius;
  box-shadow: $box-shadow;
  max-width: 1200px;
  margin: auto;
  text-align: center;
}
h1,
h2,
h3 {
  color: #0056b3;
  margin-bottom: 30px;
  font-weight: 500;
  font-size: 2em;
}

.sectionROI {
  padding: 20px;
  margin-bottom: 20px;
  border-radius: 8px;
}
.values,
.reasons,
.partner-section {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px;
}
.value-box,
.reason-box,
.partner-box {
  background: #fff;
  padding: 20px;
  border-radius: 10px;
  border: 1px solid #00218f;
  box-shadow: 0px px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
}
.flip-card {
  width: 250px;
  height: 300px;
  perspective: 1000px; /* Effet 3D */
}

.flip-card-inner {
  width: 100%;
  height: 100%;
  position: relative;
  transition: transform 0.6s;
  transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
  transform: rotateY(180deg);
}

.flip-card-front,
.flip-card-back {
  width: 100%;
  height: 100%;
  position: absolute;
  backface-visibility: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-radius: 10px;
  border: 1px solid #00218f;
  box-shadow: 0px 6px 6px rgba(0, 0, 0, 0.1);
  text-align: center;
  padding: 20px;
  background: #fff;
}

.flip-card-back {
  transform: rotateY(180deg);
  background: #00218f;
  color: white;
}
.cta {
  text-align: center;
  margin-top: 20px;
}
.cta a {
  display: inline-block;
  padding: 12px 24px;
  background: #0056b3;
  color: white;
  text-decoration: none;
  border-radius: 5px;
  margin: 10px;
  transition: background-color 0.3s ease;
}
.cta a:hover {
  background-color: #004080;
}
.roi-calculator {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 20px;
  background: #e6f2ff;
  border-radius: 8px;
  margin-top: 20px;
  position: relative;
}
.roi-input-row,
.roi-output-row {
  display: flex;
  //flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}
.roi-input-group {
  display: flex;
  flex-direction: column;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 20px;
  background: #fff;
  flex: 2;
}
.roi-input-group h4 {
  margin-bottom: 10px;
  font-size: 1.2em;
  color: #0056b3;
}
.roi-input-field-container {
  display: flex;
  gap: 20px;
}
.roi-input-field {
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  margin-bottom: 20px;
}
.roi-input-field label {
  width: 100%;
  text-align: center;
  margin-bottom: 10px;
  font-weight: bold;
}
.roi-input-field span {
  width: 80px;
  text-align: center;
  background: #0056b3;
  color: white;
  border-radius: 5px;
  padding: 5px;
  margin-top: 10px;
  font-size: 0.9em;
}
.roi-input input[type="range"] {
  width: 100%;
}
.roi-result {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  flex: 1;
  margin-top: 10px;
  font-weight: bold;
  padding: 20px;
  border-radius: 8px;
  background: linear-gradient(135deg, #ffffff, #e6f2ff);
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  transition: transform 0.3s ease;
}
.roi-result:hover {
  transform: translateY(-5px);
}
.roi-result-label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #0056b3;
  font-weight: bold;
  font-size: 1.2em;
}
.roi-result-value {
  font-size: 1.5em;
  margin-bottom: 5px;
  color: #009688;
  font-size: 1.8em;
  margin-top: 10px;
}
.roi-result-subtext {
  font-size: 0.9em;
  color: #666;
  color: #777;
  font-size: 0.9em;
  margin-top: 10px;
  display: block;
}
.roi-feedback {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.2em;
  color: #009688;
  opacity: 0;
  transition: opacity 0.3s ease;
}
.footnote {
  font-size: 0.8em;
  color: #777;
  margin-top: 20px;
  text-align: center;
}
.okr-footnote {
  font-size: 0.8em;
  color: #777;
  margin-top: 10px;
  text-align: left;
}
@media (max-width: 768px) {
  .values,
  .reasons,
  .partner-section {
    grid-template-columns: repeat(1, 1fr);
  }
  .roi-calculator {
    flex-direction: column;
    text-align: center;
  }
  .roi-input-field {
    width: 100%;
  }
  .roi-output-row {
    flex-direction: column;
    align-items: center;
  }
  .roi-feedback {
    top: -20px;
  }
}
