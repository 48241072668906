.backarrow {
  cursor: pointer;
}

.mainarea {
  overflow-x: hidden !important; // Disable horizontal scrolling
}

.progressDiv {
  display: inline-block;
  padding: 4px 8px;
  font-size: 11px;
  color: #fff;
  border-radius: 7px;
  background-color: #3e98c7;
  width: auto;
}

.progressCircle {
  height: 80px;
  width: 80px;
  display: inline-block;
}
.overall-progress-main h4 {
  font-size: 15px;
  margin: 0px 0 14px 0;
}

.headertop {
  display: flex;
  margin-top: -20px;
  margin-bottom: 50px;
  justify-content: flex-end !important;
}

.switchtab {
  display: flex;
  margin-top: 60px;
  margin-bottom: -115px;
  margin-left: 25px;
}

.nav-pills .nav-link {
  color: #000000; // Default text color
  background-color: #efefef; // Default background color for non-active state
  border-radius: 8px; // Rounded corners

  &.active,
  &:focus {
    color: #000000 !important;
    background-color: #ffffff !important;
  }
}

.transition {
  transition: all 300ms ease-in-out;
}

.objective-card {
  background-color: white; /* White background */
  border-radius: 0px 8px 8px;
  margin-top: 20px;
  margin-left: 28px;
  position: relative; /* Ensure the position is relative for any absolute positioning inside */
}

.card-body {
  border-style: none !important;
}

.dropdown.filter-dropdown.ms-3 {
  padding: 16px;
}

.card.mb-1 {
  border: none;
  border-radius: 8px;
  position: relative;
  padding-left: 10px;
}

.card.mb-1:not(:last-child)::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 1%;
  width: 99%;
  height: 6px;
  background-color: #f5f6fb;
}

.tdoksnames.text-capitalize.large-font {
  font-size: 20px !important; /* Adjust the value as needed */
}

.separator-bar {
  border-bottom: 2px solid #f5f6fb;
  position: absolute;
  left: 8%;
  width: 92%;
  margin-top: 20px;
}
.objective-section:hover .separator-bar {
  opacity: 0; /* Fade out */
  transition: opacity 0.2s ease;
}

.new-separator-bar {
  border-bottom: 4px solid #f5f6fb;
  width: 100%;
}

.flex-half {
  flex: 0 0 40%; /* Ensures the element takes up exactly 50% of the available width */
  max-width: 40%;
}

.calendar-icon {
  color: rgb(107, 107, 107);
}

.common-w.actions {
  display: flex;
  justify-content: flex-end;
  //margin-left: -40px;
  margin-top: 0px !important;
}

.objective-section {
  position: relative;
  border-radius: 8px;
  padding: 20px 20px 0px;
}

.objective-section::before {
  content: "";
  position: absolute;
  left: 0px;
  top: calc(50% - 29.5px);
  height: 70px;
  width: 2px;
  background-color: #f5f6fb;
}

.objective-section:hover {
  background-color: #f5f6fb;
}

.obj-titles {
  color: #000;
  cursor: default;
  text-decoration: none;
}

.key-result-section {
  position: relative;
  border-radius: 8px;
}

/* Styling for separator bars */
.key-result-section::before,
.key-result-section::after {
  content: "";
  position: absolute;
  background-color: #efefef;
}

.key-result-section::before {
  position: absolute;
  left: 90px;
  top: 10%;
  height: 80%;
  width: 2px;
  background-color: #f5f6fb;
}

/* Bottom border for separator, only on non-last-child items */
.key-result-section:not(:last-child)::after {
  border-bottom: 2px solid #f5f6fb;
  position: absolute;
  left: 8%;
  width: 92%;
}

/* Add hover effect to hide the separators */
.key-result-section:hover::before,
.key-result-section:hover::after {
  opacity: 0; /* Makes separators invisible */
  transition: opacity 0.2s ease; /* Smooth transition for hiding */
}

.key-result-section:hover {
  border: unset !important;
}

.key-result-main:hover {
  background-color: #f5f6fb; /* Light blue background on hover */
  border-radius: 8px;
}

.card_line:hover {
  background-color: #f5f6fb; /* Light blue background on hover */
  border-radius: 8px;
}
.battery-bar {
  background-color: #28a745;
}

.oksrs_alignment {
  margin-top: 20px;
}

.custom-height {
  height: 50px;
}

.btn-primary:hover {
  background-color: #282dc4 !important;
}
.btn-primary:disabled {
  color: #0f149f !important;
  background-color: #fff !important;
  border-color: #0f149f !important;
  opacity: 0.7 !important;
}

.btn-secondary {
  color: #0f149f !important;
  background-color: #fff !important;
  border-color: #0f149f !important;
}

.bar {
  border-bottom: 1px solid #dee2e6;
}

.css-13cymwt-control {
  background-color: #f5f6fb !important;
  border-color: none;
  border: none;
}

.css-t3ipsp-control {
  border-color: none !important;
  border: none !important;
}
textarea.form-control {
  background-color: #f5f6fb !important;
}
.half-input {
  width: 50%;
}

.row1 {
  display: flex;
  width: 120%;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -10px;
  margin-right: 65px;
  margin-bottom: 5px;
}

.modal-title {
  background-color: #3d93c1;
  color: white;
}

.modal-footer {
  background-color: #f5f5f5;
}

.row {
  margin-left: -5px;
  margin-right: -10px;
  //margin-bottom: 15px;
}
.row-employee {
  margin-left: -15px;
}

.bold-label-down {
  font-weight: bold;
  margin-bottom: 0px;
}

.card_line_header h6 {
  text-transform: capitalize;
  font-size: 13px;
  // color: #929395;
  color: #000;
  font-weight: 400;
}
.card_line_header {
  padding: 10px;
  border-radius: 5px;
  // border: 1px solid #d9d9d9;
  background: #ffffff;
  margin: 0 0 10px 28px;
  font-size: 13px;
  color: #000;
  //color: #929395;
  font-weight: 400;
}

.alignment-background {
  background-color: #ffffff;
  border-radius: 0px 8px 8px;
  margin-left: 28px;
  // padding-right: 10px;
  // padding-top: 10px;
  padding: 20px;
}

.margin-left {
  margin-left: 33px !important;
}

.progress-bar-text {
  position: absolute;
  margin-left: 5px;
  color: #ffffff !important; /* Text color for visibility */
  font-weight: bold;
  font-size: 14px; /* Adjust font size as needed */
  pointer-events: none; /* Prevent interaction with the text */
}

.modal-body {
  overflow-x: hidden !important; // Disable horizontal scrolling
}

.modal-links {
  padding: 10px;
  background-color: #f5f5f5;
  //background-color: rgb(255, 255, 255);
  // border-color: #0f149f;
  // border-style: solid;
  // border-width: 1px;
}

.com-button {
  background-color: #717374;
  color: white !important;
  border-radius: 5px;
  padding: 5px 10px;
  margin-right: 10px;
  border: none;
  cursor: pointer;
  width: 150px;
}

.row-buttons {
  display: flex;
  width: 120%;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 65px;
  margin-bottom: 5px;
}

.modal-body-emails {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  padding: 1rem;
  height: auto;
}

.percentage {
  position: absolute;
  top: 50%;
  left: 30%;
  transform: translate(-50%, -50%);
  align-items: center;
  justify-content: center;
  color: rgba(0, 0, 0, 0.7); /* Text color */
  pointer-events: none; /* Prevent interaction with the text */
}

.progreebar-main {
  display: flex !important; /* Force flex display */
  flex-direction: row !important;
  align-items: center !important;
  width: 100%;
}

.percentage-before,
.percentage-after {
  display: flex;
  align-items: center;
  white-space: nowrap;
  flex-shrink: 0; /* Prevent shrinking */
  margin: 5px;
}

.progress {
  flex: 1;
  min-width: 100px;
}

/* Remove any margins from paragraphs */
.percentage-before p,
.percentage-after p {
  margin: 0;
  padding: 0;
}

.input.form-control1 {
  background-color: #d5d5d5;
}

.form-control1 {
  display: block;
  width: 100%;
  font-size: 14px;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  min-height: calc(1.5em + 12.75rem + calc(var(--bs-border-width) * 2));
}

button#CloseSessionModal {
  margin-right: 10px;
}

.card-title {
  font-size: 30px;
  font-weight: bold;
  color: #00218f;
  text-align: center;
  display: block;
  margin-bottom: 15px;
}
/* Battery styles */
.battery {
  min-width: 120px;
  height: 40px;
  border: 3px solid #333;
  border-radius: 6px;
  position: relative;
  padding: 3px;
  background: white;
}

/* Battery tip */
.battery::after {
  content: "";
  position: absolute;
  width: 8px;
  height: 16px;
  background: #333;
  top: 50%;
  right: -11px;
  transform: translateY(-50%);
  border-radius: 0 4px 4px 0;
}

.battery-bar {
  height: 100%;
  border-radius: 3px;
  transition: all 0.5s ease-in-out;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  font-weight: bold;
}

/* Color variations based on percentage */
.battery-bar[data-level="low"] {
  background: #dc3545;
}

.battery-bar[data-level="medium"] {
  background: #ffc107;
}

.battery-bar[data-level="high"] {
  background: #28a745;
}

/* Custom range input styling */
.trust-range {
  width: 100%;
  height: 25px;
  margin: 10px 0;
  background: transparent;
  -webkit-appearance: none;
}

.trust-range::-webkit-slider-thumb {
  -webkit-appearance: none;
  width: 25px;
  height: 25px;
  background: #28a745;
  border-radius: 50%;
  cursor: pointer;
  margin-top: -8px;
}

.trust-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 4px;
}

/* Animation for the battery charging effect */
.battery-bar::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 50%,
    rgba(255, 255, 255, 0) 100%
  );
  animation: charge 1.5s infinite;
}

@keyframes charge {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(100%);
  }
}

.row-input {
  margin-left: -3px;
  margin-bottom: 15px;
}
.status-pastille {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin-left: 325px;
  margin-bottom: -8px;
}

.status-pastille.green {
  background-color: green;
}

.status-pastille.red {
  background-color: red;
}

.good-center {
  margin-left: 0.5px;
}

.more-marginleft {
  margin-left: 110px;
}

.text-box {
  white-space: pre-wrap; /* Preserves line breaks */
  word-wrap: break-word; /* Wrap text within the boundaries */
  border: 1px solid #ced4da; /* Matches input box style */
  padding: 10px;
  border-radius: 5px;
  min-height: 38px; /* Default input height */
  max-width: 100%;
}

.auto-expand {
  overflow: hidden;
  resize: none;
  min-height: 38px;
  width: 100%;
}
