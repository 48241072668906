@import "./mixins";

// Colors
@import "./colors";
@import "./table";

.cursor-help {
  cursor: help;
}

.text-xs {
  font-size: 11px;
}

.tooltip {
  font-size: 12px;
}

.hintCls {
  font-size: 12px;
  margin-top: 3px;
  margin-bottom: -20px;
}

//////////##### Modal Google Analytics end ///////////#####
///
///
///
///
// Dashboard New CSS

/* Box sizing rules */
*,
*::before,
*::after {
  box-sizing: border-box;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin-block-end: 0;
  margin: 0;
}

/* Remove list styles on ul, ol elements with a list role, which suggests default styling will be removed */
ul[role="list"],
ol[role="list"] {
  list-style: none;
  padding: 0;
}

/* Set core root defaults */
html:focus-within {
  scroll-behavior: smooth;
}

/* Set core body defaults */
body {
  font-family: "Be Vietnam Pro", sans-serif;
  text-rendering: optimizeSpeed;
  line-height: 1.5;
  background-color: #fff;
}

/* A elements that don't have a className get default styles */
a:not([class]) {
  text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img,
picture {
  max-width: 100%;
  display: block;
  height: auto;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
  font: inherit;
}

/* Remove all animations, transitions and smooth scroll for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }

  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important;
    animation-iteration-count: 1 !important;
    transition-duration: 0.01ms !important;
    scroll-behavior: auto !important;
  }
}

.inputWrapper {
  position: relative;
}

.inputWrapper i {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 10px;
}

.loginclass {
  width: 100%;
  padding: 10px;
}

.inner_dial .react-tel-input .form-control {
  height: 47px;
  width: 300px;
  outline: none;
}

.red {
  color: red;
}

.form-control {
  height: calc(1.1em + 0.75rem + 10px);
  // line-height: 2;
}

.btn-white-border {
  border: 1px solid #fff;
  color: #fff;
  width: 150px;
  padding: 10px;
  text-decoration: none;
}

.btn-white-bg {
  border: 1px solid #fff;
  background-color: #fff;
}

.logo {
  width: auto;
  height: 50px;
}

.homenavs {
  // position: fixed;
  top: 0px;
  width: 100%;
}

.hero_section {
  background-color: #f2f2ff;
  height: 150vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  margin-bottom: 10vh;
}

.hero-wrap-text {
  margin-top: 120px;
}

.hero_section:after {
  content: "";
  position: absolute;
  bottom: -7px;
  background-image: url("../../public/assets/img/heroslice.png");
  background-position: center;
  background-repeat: no-repeat;
  display: block;
  width: 100%;
  padding: 40px;
  background-size: 100% 100%;
}

.navwhite .nav-item .nav-link {
  color: #00218f !important;
  margin-right: 40px !important;
  font-size: 18px;
}

.navwhite .nav-item .nav-link:hover {
  text-decoration: underline !important;
  text-underline-offset: 8px;
}

.navbar-light .navbar-nav .nav-link.active,
.navbar-light .navbar-nav .show > .nav-link {
  color: rgba(255, 255, 255, 0.9) !important;
  text-decoration: underline !important;
  text-underline-offset: 8px;
  font-weight: 500;
}

.btn-white-border {
  border: 1px solid #00218f;
  color: #fff !important;
  width: 150px;
  padding: 10px 10px;
  background: transparent;
  border-radius: 10px;
  font-size: 14px;
  transition: all 0.3s ease-in;
  background-color: #00218f;
}

.btn-white-border:hover {
  border: 1px solid #00218f;
  color: #00218f !important;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  font-weight: 500;
}

.btn-white-border-main {
  border: 1px solid #00218f;
  color: #fff !important;
  width: 380px;
  padding: 10px 10px;
  background: transparent;
  border-radius: 10px;
  font-size: 24px;
  transition: all 0.3s ease-in;
  background-color: #00218f;
}
.btn-white-border-main:hover {
  border: 1px solid #00218f;
  color: #00218f !important;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  font-weight: 500;
}

.btn-white-menu {
  border: 1px solid #00218f;
  color: #00218f;
  width: 157px;
  padding: 10px 10px;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.btn-white-menu:hover {
  border: 1px solid #00218f;
  color: #fff;
  font-weight: 500;
  background: #00218f;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

.btn-white-bg {
  border: 1px solid #00218f;
  color: #00218f;
  width: auto;
  min-width: 150px;
  padding: 10px 20px;
  background: #fff;
  border-radius: 10px;
  font-size: 14px;
  transition: all 0.2s ease-in-out;
}

.btn-white-bg:hover {
  border: 1px solid #00218f;
  color: #fff;
  font-weight: 500;
  background: #00218f;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
}

span.textunderline {
  font-weight: 700;
  color: #37e2d5;
  text-decoration: underline;
  text-underline-offset: 8px;
}

.titleh1 {
  font-size: 3rem;
  margin-bottom: 30px;
  color: #0c0047;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
}

.ptext {
  // color: #fff;
  text-align: center;
  font-size: 25px;
  font-style: normal;
  font-weight: 400;
  line-height: 25px;
  /* 160% */
  letter-spacing: -0.408px;
  margin-bottom: 10vh;
  line-height: 1.5;
}

.img_secthirds {
  margin-top: -300px;
  position: relative;
}
.img_right img {
  height: 480px;
  object-fit: contain;
  border-radius: 10px;
  display: block;
  margin: 0 auto;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}

.common-padding {
  padding: 100px 0px;
}

.main-title {
  color: #0c0047;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 0.18px;
  // text-transform: capitalize;
  margin-bottom: 30px;
}

.sub-title {
  color: #969696;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.408px;
}

.card_inner {
  border-radius: 15px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 20px;
}

.bglightblue {
  border-radius: 10px;
  background: #efefff;
  padding: 10px 10px;
  display: inline-block;
}

.cards_title {
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 30px;
  /* 136.364% */
  letter-spacing: -0.408px;
}

.cards_ptext {
  color: #969696;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 170%;
  /* 23.8px */
  letter-spacing: -0.408px;
  height: 172px;
}

.acards {
  color: #00218f;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.408px;
  text-decoration-line: underline;
}

.titled_text {
  padding-bottom: 60px;
}

@media (min-width: 769px) and (max-width: 1399px) {
  .hero_section {
    max-height: 700px !important;
    margin-bottom: 100px;
  }
}

@media (min-width: 1400px) {
  .hero_section {
    max-height: 700px !important;
    margin-bottom: 230px;
  }
}

// gourav css

.team_h2 {
  color: #0c0047;
  font-size: 28px;
  font-weight: 700;
  letter-spacing: 0.26px;
  line-height: 47px;
  max-width: 368px;
}

.platform_p {
  color: #969696;
  font-size: 14px;
  font-style: normal;
  max-width: 399px;
  font-weight: 400;
  letter-spacing: -0.408px;
  margin: 20px 0;
}

.number_text {
  color: #343434;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  /* 157.143% */
  letter-spacing: -0.408px;
  margin-bottom: 0px;
}

.imges_list {
  display: flex;
  list-style: none;
  padding: 0px;
  margin: 0px;
  margin-left: 8px;
}

.space_gap {
  gap: 15px;
}

.custom-btn-drk {
  border-radius: 7px;
  width: 150px;
  margin: 30px 0 0 0;
}

.small_mg {
  border-radius: 50px;
  object-fit: cover;
  width: 24px;
  height: 24px;
}

ul.imges_list li {
  margin-left: -8px;
}

.Dashboard_light {
  width: 100%;
  height: 300px;
  object-fit: contain;
}

.team_seciton {
  background-image: url("../../public/assets/img/images/dasbhoard_bg.png");

  width: 100%;
  background-position: bottom;
  background-repeat: no-repeat;
  background-size: cover;
}

/* section 1 end  */

/* section 2 start  */

.user_img {
  width: 72px;
  height: 72px;
  border: 40px;
}

.profiles {
  color: #343434;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: -0.045px;
  margin-bottom: 0px;
}

.profile_detail {
  color: #969696;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: -0.035px;
  margin-bottom: 0px;
}

.user_box {
  align-items: center;
  gap: 20px;
  margin-bottom: 24px;
}

.box-border {
  border-radius: 20px;
  border: 1px solid #eaeaea;
  background: #fff;
  padding: 20px;
  margin-bottom: 24px;
}

.detail_para {
  color: #969696;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: -0.035px;
}

.star_list_bx {
  list-style: none;
  display: flex;
  margin: 0px;
  padding: 0px;
}

.uses_names {
  color: #0c0047;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 81px;
  margin-bottom: 0px;
  letter-spacing: 0.2px;
}

.arrow_move {
  width: 64px;
  height: 64px;
  border-bottom: 50px;
}

.side_arrow {
  gap: 15px;
}

/* section 2 end  */

/* section 3 start  */

.footer_section {
  background: #f9f9f9;
}

.footer_logo_bx {
  width: 100%;
  height: auto;
  max-width: 200px;
}

.footer_everytime {
  color: #343434;
  font-size: 16px;
  font-weight: 400;
  max-width: 200px;
  line-height: 32px;
  margin-top: 30px;
}

.footer_heading {
  color: #343434;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  margin-bottom: 24px;
}

.footer_list_items .list-group-item {
  background-color: transparent;
  border: unset;
  padding: 0px;
  margin-bottom: 20px;
}

.footer_list_items .list-group-item a {
  text-decoration: none;
  color: #343434;
  font-size: 15px;
  font-weight: 400;
  line-height: 20px;
}

.footer_list_items .list-group-item a:hover {
  color: #00218f;
}

.Subscribe_heading {
  color: #00218f;
  font-size: 18px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
}

.footer_signup {
  color: #39364e;
  font-size: 15px;
  font-style: normal;
  font-weight: 500;
  line-height: 25px;
  margin: 11px 0;
}

.email_svg {
  position: absolute;
  top: 15px;
  left: 15px;
}

.enter_mailbx {
  border-radius: 3px;
  border: 2px solid #e0e0e0;
  padding: 6px 12px 6px 44px;
  height: 50px;
  background: #fff;
  margin-bottom: 12px;
}

.enter_mailbx::placeholder {
  color: rgba(57, 54, 78, 0.31);
  font-size: 16px;
  font-weight: 500;
  line-height: normal;
}

.footer_subscribe_now {
  border-radius: 3px;
  background: #00218f;
  color: #fff;
  padding: 14px 0px;
  width: 100%;
  font-size: 18px;
  font-weight: 500;
  border: unset;
}

.copy_footer {
  color: #343434;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  background: #f9f9f9;
}

.box_flex {
  display: grid;
  gap: 15px;
  justify-content: space-between;
  grid-template-columns: auto auto auto auto auto;
}

/* section 3 end  */

@media (max-width: 768px) {
  .box_flex {
    display: grid;
    grid-template-columns: auto auto;
  }
}

@media (max-width: 576px) {
}

@media (max-width: 992px) {
  .box_flex {
    display: grid;
    grid-template-columns: auto auto auto;
  }

  .uses_names {
    font-size: 24px;
    line-height: unset;
  }

  .arrow_move {
    width: 44px;
    height: 44px;
  }

  .team_h2 {
    font-size: 24px;
    line-height: 36px;
  }
}

@media (max-width: 540px) {
  .team_h2 {
    font-size: 20px;
    line-height: unset;
  }

  .number_text {
    font-size: 14px;
  }

  .space_gap {
    gap: 10px;
  }

  .footer_everytime {
    margin-top: 18px;
  }

  .box_flex {
    display: grid;
    grid-template-columns: auto;
  }
}

.text_primary {
  color: #00218f;
}

.switch {
  font-size: 17px;
  position: relative;
  display: inline-block;
  width: 3.5em;
  height: 2em;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: 0.4s;
  border-radius: 30px;
}

.pointer {
  cursor: pointer;
}

.slider:before {
  position: absolute;
  content: "";
  height: 1.4em;
  width: 1.4em;
  border-radius: 20px;
  left: 0.3em;
  bottom: 0.3em;
  background-color: white;
  transition: 0.3s cubic-bezier(0, 2.18, 0.64, 0.69);
}

input:checked + .slider {
  background-color: #00218f;
}

input:focus + .slider {
  box-shadow: 0 0 1px #00218f;
}

input:checked + .slider:before {
  transform: translateX(1.5em);
}

.active_card {
  background-color: #00218f;
  color: #fff !important;
  // border-radius: 20px;
}

// .active_card .text-muted {
//   color: #cccccc !important;
// }

.active_card .text_primary {
  color: #fff !important;
}

// .active_card .btn-primary {
//   background-color: #fff !important;
//   color: #00218F !important;
// }

.plantext {
  color: #000000;
  font-size: 15px;
  font-weight: 600;
}

.radio_input + .radio_label {
  /* Unchecked style  */
  background-color: #ccc;
  color: #333;
  padding: 5px 10px;
  font-family: sans-serif;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-radius: 4px;
  display: inline-block;
  margin: 0 10px 10px 0;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  transition: all 0.6s ease;
}

.radio_input:checked + .radio_label {
  /* Checked style  */
  background-color: #0f149f;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  color: #fff;
}

.form-control:focus {
  color: #495057;
  background-color: #fff;
  border-color: #00208c;
  outline: 0;
  box-shadow: none;
}

.radio_input:checked + .radio_label {
  /* Checked style  */
  background-color: #0f149f;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}

.radio_input:checked + .radio_label .ions svg path {
  fill: #fff;
}

p.ions {
  margin: 0;
  text-align: center;
  padding: 5px;
}

.eye_right {
  position: absolute;
  right: 10px;
  transform: translateY(-50%);
  top: 50%;
}

.inner_dial .form-control {
  width: 100% !important;
}

.dail {
  position: absolute;
  left: 82px;
  top: 2px;
  padding: 10px;
  border: 0;
  background-color: #fff !important;
  border-left: 1px solid #dbdbdb;
  width: 78.6%;
}

input.form-control {
  font-size: 14px;
  background-color: #f5f6fb;
}

.dail:focus {
  outline: 0;
  background-color: #fff !important;
}

.inner_dial {
  position: relative;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: -internal-light-dark(
    rgba(232, 240, 254, 0),
    rgba(255, 255, 255, 0)
  ) !important;
  color: fieldtext !important;
}

.logo_login {
  width: auto;
  height: 50px;
  margin: 0 auto;
  cursor: pointer;
}

.centerLogin h3 {
  letter-spacing: -1px;
  font-size: 26px;
}

.ions svg {
  height: 20px;
}

.small_sdies {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
}

.ulclasssides {
  padding: 0px;
  list-style: none;
}

.faviconimg {
  width: auto;
  height: 20px;
  margin: 0 auto;
}

.hegiht100 {
  height: 100vh;
}

.inner_wraping {
  padding: 10px;
  width: 50px;
  background: linear-gradient(360deg, #0056ff, #00218f);
  text-align: center;
  justify-content: center;
  display: flex;
}

.ulclasssides li {
  margin: 5px 2px 14px;
  cursor: pointer;
}

.ulclasssides li .aclass {
  height: 30px;
  width: 30px;
  display: block;
}
.ulclasssides li .aclass svg {
  color: #fff;
}
.ulclasssides li span > img {
  cursor: pointer;
  filter: invert(1);
}
.ulclasssides li.active_lii .aclass svg {
  color: #000;
}
.ulclasssides li .aclass_data:hover {
  background-color: #e3e3e6;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transition: 0.3s ease-out;
  display: block;
}

// OKRs CSS
.create_okrs {
  margin: 40px 0 0 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ulclasssides li span img {
  cursor: pointer;
}

.ulclasssides li .aclass_data {
  background-color: #0f149f;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transition: 0.3s ease-out;
  display: block;
}

.ulclasssides li .aclass.active {
  background-color: #0f149f;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transition: 0.3s ease-out;
  display: block;
  color: #fff;
}

.ulclasssides li .aclass_data svg {
  color: #fff;
}

.ulclasssides li .aclass_data:hover svg {
  color: #0f149f;
}
.logo {
  width: auto;
  height: 50px;
}

.sidebar_new {
  display: flex;
  flex-direction: row;
  position: fixed;
  height: 100vh;
  z-index: 9;
  transition: 0.8s ease;
}

.big_sideswrap {
  width: 230px;
  background: whitesmoke;
  border-right: 1px solid rgba(206, 206, 206, 0.973);
  position: relative;
}

.sidebar_togle {
  transition: 0.8s ease;
}

.name_menus {
  display: flex;
  justify-content: space-between;
}

.fs-16 {
  font-size: 16px;
  font-weight: 600;
}

.headerlogo.collpase_data {
  border-bottom: 1px solid rgba(206, 206, 206, 0.973);
  padding: 15px 15px;
  height: 60px;
}

.itesm_list ul {
  padding: 0px;
  list-style: none;
}

.itesm_list ul li {
  padding: 10px 15px 10px;
  font-size: 14px;
  margin: 10px;
  cursor: pointer;
  transition: 0.3s all;
}

.itesm_list .ulclasssides li:hover {
  background-color: #e8ecf2;
  border-radius: 50px;
  color: #444040;
  transition: 0.3s all;
}

.active_li {
  background-color: #e8ecf2;
  border-radius: 50px;
  color: #444040;
  transition: 0.3s all;
}
.active_lii {
  background-color: white;
  border-radius: 50px;
  color: #444040;
  transition: 0.3s all;
}

.main-navbar {
  background-color: #fff;
  color: #fff;
  width: calc(100% - 250px);
  border-bottom: 1px solid rgba(206, 206, 206, 0.973);
  position: fixed;
  z-index: 9;
  right: 0;
  display: flex;
  align-items: center;
  top: 1px;
}

img.rounded-circle {
  width: 25px;
  height: 25px;
  margin-left: 2px;
  object-fit: contain;
}

.navbar_header {
  width: 250px;
  position: fixed;
  left: 50px;
}

.mai_data_full {
  width: calc(100% - 280px);
  padding-top: 59px;
  padding-left: 0;
  padding-right: 0;
  margin-left: auto;
  transition: 0.4s ease;
  overflow-y: scroll;
  background: #00218f0a;
}

.half_sides {
  width: 100%;
  margin-left: 50px;
  overflow-y: scroll;
}

.inner_ful_sides_new {
  transition: 0.8s ease-out;
  border-right: 1px solid rgba(206, 206, 206, 0.973);
  padding-right: 15px;
}

.header_sidebars {
  border-bottom: 1px solid rgba(206, 206, 206, 0.973);
  padding: 0px 20px;
  position: fixed;
  z-index: 9;
  top: 0;
  background: #fff;
  // width: calc(100% - 280px);
  width: 100%;
  overflow: hidden;
  height: 60px;
}

.name_s p {
  font-size: 20px;
  font-weight: 600;
}

.h-60 {
  height: 60px;
}

.half_sides .mainarea {
  padding: 80px 20px 0px;
}

.mainarea {
  padding: 30px 20px 0px;
}

.table td,
.table th {
  padding: 0.75rem;
  vertical-align: top;
  border-top: 1px solid transparent;
}

.fc-license-message {
  display: none !important;
}

.mvp_table table th {
  font-size: 13px;
  color: #929395;
  font-weight: 400;
  border-bottom: 1px solid #dee2e6 !important;
}

.mvp_table table td span img {
  border-radius: 50%;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.mvp_table table td span,
.mvp_table table td p {
  font-size: 11px !important;
}
.mvp_table table td.progressbar span {
  font-size: 15px !important;
}
.mvp_table table td span img {
  border-radius: 50%;
}
td.text-danger.progressbar span {
  color: #3b7aad !important;
  font-weight: 600;
  font-size: 15px;
}
.dropdown-item .fa-trash:before {
  color: #c40202;
}
.dropdown-item:active .fa-trash::before {
  color: #fff !important;
}
div:where(.swal2-container) button:where(.swal2-styled).swal2-confirm {
  background-color: #00218f !important;
  border-radius: 10px !important;
}
button.swal2-cancel.swal2-styled.swal2-default-outline {
  border-radius: 10px !important;
  background-color: #6c757d !important;
}
h2#swal2-title {
  font-size: 20px;
  margin: 8px 0 0 0 !important;
}
div#swal2-html-container {
  margin: 5px 0 12px 0;
  font-size: 16px;
  color: #686868;
}
span.notFound {
  margin: 0 auto;
  text-align: center !important;
  font-size: 12px;
  color: #595252;
  display: block;
}
div:where(.swal2-icon).swal2-warning {
  border-color: #c9620a !important;
  color: #c9620a !important;
}
.dropdown-item:active {
  color: #fff !important;
}
.mvp_table table td {
  font-size: 14px;
  padding: 17px 15px 19px;
  vertical-align: top;
}

// .mvp_table table td:first-child {
//   width: 100px;
// }

// .close {
//   float: right;
//   font-size: 46px !important;
//   font-weight: 300;
//   line-height: 1;
//   color: #0f149f;
//   text-shadow: 0 1px 0 #fff;
//   opacity: .5;
// }

.btn {
  border-radius: 10px;
  padding: 10px 15px;
}

.custom_drops a {
  font-size: 16px;
}

.pulse_profile {
  margin-left: 30px;
  width: 30px;
  height: 30px;
  font: 12px/100px "Barlow Semi Condensed", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #fff;
  background: rgba(30, 144, 255, 0.6);
  border-radius: 50%;
  animation: shadow-pulse 1s infinite;
  margin-left: 0;
}

@keyframes shadow-pulse {
  0% {
    box-shadow: 0 0 0 0px rgba(0, 0, 0, 0.2);
  }

  100% {
    box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
  }
}

.loaderDiv {
  z-index: 99999;
  backdrop-filter: blur(2px);
  align-items: center;
  justify-content: center;
  display: flex;
  position: fixed;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
}

.pageLoader {
  max-width: 50px;
}

img.loaderlogo {
  max-width: 50px;
  // animation: rotation 3s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.cardselected .card_bank {
  background-color: #00104d;
  cursor: pointer;
}

.cardselected .action-dots .colorred {
  color: #fff;
}

// .cardselected {
//   background-color: #e9e9e9;
//   border-radius: 15px;
// }

.tox-statusbar {
  display: none !important;
}

.tox-bottom-anchorbar {
  display: none !important;
}

// Loader start
img.loaderlogo {
  max-width: 50px;
}

.modal-60w {
  max-width: 800px;
}

.text_small {
  font-size: 20px;
}

.total_amt {
  background-color: rgb(204, 204, 204);
  padding: 10px;
  border-radius: 5px;
}

label.headingcards {
  padding: 10px 0px 6px;
  font-weight: 600;
  font-size: 20px;
}

.adds_plan {
  padding: 20px 15px;
}

// bank card css start

/*section::before {
  content: "";
  position: absolute;
  bottom: -40%;
  left: 40%;
  width: 600px;
  height: 600px;
  background: linear-gradient(#ff7402, #fff55e);
  border-radius: 50%;
}*/

.card_bank {
  position: relative;
  width: 240px;
  height: 150px;
  background: #454545;
  border-radius: 15px;
  cursor: pointer;
}

.card_bank .face {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  box-shadow: 0 15px 35px rgba(0, 0, 0, 0.5);
  border-radius: 15px;
  background: rgba(184, 181, 181, 0.26);
  backdrop-filter: preserve-3d;
  transition: 1s;
}

// .card_bank .face.front:before {
//   content: "";
//   position: absolute;
//   bottom: 40px;
//   right: 40px;
//   width: 40px;
//   height: 40px;
//   background: #e00d0d;
//   border-radius: 50%;
//   opacity: 0.5;
// }

// .card_bank .face.front:after {
//   content: "";
//   position: absolute;
//   bottom: 40px;
//   right: 80px;
//   width: 40px;
//   height: 40px;
//   background: #ffce00;
//   border-radius: 50%;
//   opacity: 0.7;
// }

.card_bank .face.front .debit {
  position: absolute;
  left: 20px;
  top: 30px;
  color: white;
  font-weight: 500;
  font-size: 15px;
}

.card_bank .face.front .bank {
  position: absolute;
  right: 15px;
  top: 15px;
  color: #fff;
  font-weight: 700;
  font-style: italic;
  font-size: 15px;
}

.card_bank .face.front .chip {
  position: absolute;
  top: 50px;
  left: 10px;
  max-width: 70px;
}

.card_bank .face.front .number {
  position: absolute;
  bottom: 70px;
  left: 24px;
  color: white;
  font-weight: 500;
  letter-spacing: 3px;
  font-size: 18px;
  text-shadow: 0 2px 1px #0a0a0a;
  font-family: "Orbitron", sans-serif;
}

.card_bank .face.front .valid {
  position: absolute;
  bottom: 15px;
  left: 26px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  font-weight: 300;
  line-height: 1em;
  font-size: 15px;
  text-align: right;
}

.card_bank .face.front .valid span:last-child {
  margin-left: 20px;
  font-size: 16px;
  font-weight: 400;
  letter-spacing: 2px;
}

.card_bank .face.front .cardHolder {
  position: absolute;
  bottom: 15px;
  left: 26px;
  color: white;
  font-weight: 400;
  font-size: 16px;
  letter-spacing: 2px;
}

.filter_status .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  display: none;
}

// bank card css end

.mainarea {
  overflow-x: hidden;
}

// Signin page
.signIn-main {
  background: #f1f3f8;
}

form.centerLogin {
  filter: drop-shadow(0px 6px 5px rgba(68, 85, 115, 0.3));
  position: relative;
  background: #fff;
  margin: 0 auto;
  padding: 32px 32px 42px;
  box-shadow: 0 6px 8px 2px #0000001a, 0 0 1px #00000047;
  border-radius: 4px;
  -webkit-font-smoothing: antialiased;
  width: 452px;
}

.signIn-main label {
  margin-bottom: 0.375rem;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #4c5a5d;
}

.text-danger {
  font-size: 12px;
  color: #c40202 !important;
}

.bginput {
  border-radius: 3px !important;
  border: 1px solid #9dabc5;
  height: 42px;
  padding: 0 14px;
}

span.eye_right i {
  color: #ccc;
}

button.btn.light-primary-btn {
  background-color: #c7daee;
}

button.btn.light-yello-btn.mr-2 {
  background-color: #e6e6a9;
}

.yello-btn {
  background-color: #ffff00;
  color: #000 !important;
}

button.btn.mr-2.light-green-btn {
  background-color: #aae6aa;
}

.radius-sm {
  border: 0px solid;
  border-radius: 10px;
}

.no-account {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  color: #4c5a5d;
}

.signIn-main input::placeholder {
  font-size: 14px;
}

.no-account i {
  color: #00218f;
  margin: 0 0 0 7px;
}

p.accopuntt a {
  letter-spacing: -0.25px;
}

p.accopuntt {
  text-align: center;
  font-size: 14px;
  font-weight: 400;
  width: 100%;
  color: #84818a;
  word-spacing: 3px;
  margin-top: 16px;
}

.bg-whites {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border: 1px solid #efefef;
  border-radius: 7px;
  padding: 28px !important;
  background: #fff;
}

.pricing-table .table tbody tr > td {
  padding: 0 !important;
}

th.c-first-head {
  font-size: 44px;
  max-width: 303px;
  font-weight: 500;
  line-height: 50px;
  text-align: left;
  padding: 32px;
  padding-left: 0;
}

.price-text {
  font-size: 34px;
}

.features-tick {
  height: 158px;
  overflow-y: auto;
}

.features-tick li {
  font-size: 15px;
  display: flex;
}
.features-tick li i {
  margin-top: 4px;
}
.features-tick::-webkit-scrollbar {
  width: 2px !important;
  background: #ccc;
}

.features-tick::-webkit-slider-thumb {
  background: red;
}

.price-text span {
  font-size: 22px;
}

.price-text {
  font-size: 30px;
}

.inputWrapper i {
  color: #ccc;
}

.profile-tabs-left {
  background: #ecf1f6;
  //background: #F5F6FA;
  border-radius: 7px 0 0 7px;
  height: 100%;
  border-right: 1px solid #e9e7e7;
}

.profile-tabs .row {
  box-shadow: 0 0 15px #3f576e4d;
  background: #fff;
  border-radius: 7px;
  margin: 8px;
}

.profile-tabs {
  width: 670px;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  margin: 70px auto 0;
}

.profile-tabs .nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #2e2e2e;
  background-color: #ffffff;
  border-radius: 0;
  border-top-left-radius: 7px;
  text-align: left;
  font-size: 13px;
  padding: 10px;
}

.profile-tabs .nav-link {
  color: #51617a;
  border-radius: 0 !important;
  text-align: left;
  font-size: 13px;
  padding: 10px;
  border-bottom: 1px solid #e9e7e7;
}

.profile-tabs .inputFlex > label {
  width: 310px;

  font-size: 14px;
}

.profile-tabs .profile_data {
  font-size: 14px;
}

.edit-profiles {
  background: #ccc !important;
  border-radius: 4px;
  border: 0;
  padding: 4px;
  width: 35px;
  height: 35px;
  text-align: center;
}

.edit-profiles i {
  font-size: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.calendar-dropdown.dropdowns_custom {
  position: absolute;
  right: 10px;
}

.fc .fc-scrollgrid-section-liquid > td {
  height: 100%;
  padding: 0;
}

.calendar-dropdown .dropdown-menu {
  font-size: 12px !important;
}

.w-49 {
  width: 49%;
}

.w-49 .react-datepicker-wrapper {
  width: 100%;
}

.react-datepicker-wrapper {
  width: 100%;
}

.fc-timeline-event-harness {
  border-radius: 10px;
}

.calendar_table_main .fc-timeline-event-harness a {
  border-radius: 50px;
  height: 8px;
}

.calendar_table_main table td:first-child {
  width: unset;
}

.calendar_table_main .fc.fc-media-screen.fc-direction-ltr.fc-theme-standard {
  height: 400px;
}

.btn.disabled,
.btn:disabled {
  opacity: 0.15;
}

// right side modal css

.modal.drawer {
  display: flex !important;
  pointer-events: none;
}

.modal.drawer * {
  pointer-events: none;
}

.modal.drawer .modal-dialog {
  margin: 0px;
  display: flex;
  flex: auto;
  transform: translate(25%, 0);
}

.modal.drawer .modal-dialog .modal-content {
  border: none;
  border-radius: 20px 0px 0px 20px;
}

.modal.drawer .modal-dialog .modal-content .modal-body {
  overflow-y: auto;
}

.modal.drawer.show {
  pointer-events: auto;
}

.modal.drawer.show * {
  pointer-events: auto;
}

.modal.drawer.show .modal-dialog {
  transform: translate(0, 0);
}

.modal.drawer.right-align {
  flex-direction: row-reverse;
}

.modal.drawer.left-align:not(.show) .modal-dialog {
  transform: translate(-25%, 0);
}

.drawer .modal-dialog {
  max-width: 800px;
}

.modal.drawer label {
  font-size: 13px;
}

.ui-chip-icon img {
  border-radius: 1000px;
  height: 24px;
  width: 24px;
  object-fit: cover;
}

.ui-chip {
  height: 24px;
  font-size: 14px;
  padding: 0 8px;
  color: #283d5c;
  display: inline-flex;
  max-width: 100%;
  margin: 0;
  align-items: center;
  gap: 4px;
  background: #f1f3f8;
  border: none;
  border-radius: 1000px;
  transition: margin 0.3s;
  padding-left: 0;
}

.ui-chip-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-shrink: 0;
}

.progress-as-text {
  font-size: 24px;
  line-height: 24px;
  font-weight: 500;
}

.key-btn {
  font-size: 14px;
}

.key-btn:hover {
  color: #000 !important;
  background: rgba(2, 20, 33, 0.1);
}

.result-title p {
  font-size: 14px;
}

table.table.key-result-table span.btn.btn-success {
  font-size: 11px;
  padding: 2px 6px;
  border-radius: 3px;
}

span.img-width-result img {
  width: 20px;
  height: 20px;
  object-fit: cover;
  border-radius: 50%;
}

.progreebar-main {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  min-width: 50px;
}

.progreebar-main .progress {
  width: 80%;
}

.progreebar-main span {
  margin-top: 3px;
  font-size: 12px;
  color: #5e5959;
}

.update-btn {
  padding: 2px 7px;
  font-size: 12px;
  border: 1px solid #c7dae9;
  color: #333 !important;
}

span.img-width-result {
  display: flex;
}

span.img-width-result img {
  margin: 0 0 0 -4px;
}

.create-key-result .modal-title h3 {
  font-size: 22px;
}

.modal-title p {
  //color: #6a6969;
  color: #ebebeb;
  font-size: 14px;
}

.create-key-result select.form-select {
  font-size: 14px;
  color: #495057;
  height: 38px !important;
  background-color: #f5f6fb;
  box-shadow: none;
  border-color: none;
}

.location_box {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border: 1px solid #efefef;

  transition: 0.3s;
  position: absolute;
  z-index: 10;
  width: 95%;
  background: #fff;
}
.location_box_one {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  padding: 10px;
  border: 1px solid #efefef;

  transition: 0.3s;
  position: absolute;
  z-index: 9;
  width: 100%;
  background: #fff;
}
.location-box-scroll {
  height: 200px;
  overflow-y: auto;
  overflow-x: hidden;
}
.loc-manager {
  font-size: 13px;
  margin: 10px 0;
  cursor: pointer;
}

.modal-scroll {
  height: 435px;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 0 10px 0 0;
}

.modal-scroll::-webkit-scrollbar {
  width: 2px;
  background-color: #ccc;
}

.badges-result button {
  font-size: 13px;
  padding: 3px 6px;
  border-radius: 4px;
}

.mvp_table table .owners-number img {
  width: 30px;
  height: 30px;
}

.mvp_table .badge-tbl {
  font-size: 10px !important;
  border-radius: 4px;
  padding: 6px !important;
  width: 50px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

span.small-label {
  color: grey;
  font-size: 10px;
}

.ulclasssides li select {
  border: 0;
  background: transparent;
  width: 100%;
  padding: 0 !important;
  margin: 0;
}

.ulclasssides li select:focus {
  border: 0;
  outline: 0;
}

.ulclasssides li:hover .teams-select {
  color: #fff !important;
}

.ulclasssides li option {
  color: #000 !important;
}

.billing-page-head {
  display: flex;
}

.billing-page-head ul {
  margin: 0 0 0 60px;
  padding: 0;
}

.billing-page-head ul li {
  list-style: none;
  margin-bottom: 12px;
  cursor: pointer;
}

.billing-page-head ul li a {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #0f149f;
}

.billing-page-head ul li svg {
  margin-right: 6px;
}

.plan-section-detail {
  display: flex;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #eee;
  padding: 20px;
  align-items: center;
}

.plan-section-detail h5 {
  margin: 0 0 12px 0;
  letter-spacing: 0.45px;
}

.small-txt {
  color: #6e6e6e;
  font-size: 14px;
}

.upgrade-btns .btn.btn-primary {
  font-size: 12px;
  padding: 4px 16px;
}

.upgrade-btns .btn-secondary {
  background: transparent;
  border: 0;
  color: #070a4f;
  font-size: 12px;
  padding: 4px 16px;
  margin-top: 12px;
  width: 100%;
  text-align: center;
}

.upgrade-btns .btn-secondary:hover {
  color: #333 !important;
}

.subscription-detail .upgrade-btns .btn-secondary {
  padding: 0;
  text-align: left;
}

.subscription-detail .upgrade-btns .btn-secondary:focus {
  border: 0;
  outline: 0;
  box-shadow: none;
  background: transparent;
}

.subscription-detail .btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: none;
}

.subscription-detail h6 {
  font-size: 16px;
  line-height: 27px;
  font-weight: 600;
}

.subscription-detail {
  background: #f4f5f9;
  padding: 20px;
}

.subscription-detail p {
  font-weight: 600;
  margin: 0 0 30px 0 !important;
}

// .badges-color {
//   font-size: 10px;
//   border-radius: 3px;

//   padding: 4px 10px !important;
//   color: #fff;
// }

.badges-color {
  font-size: 8px;
  border-radius: 3px;
  padding: 4px 10px !important;
  color: #fff;
  display: inline-flex;
  width: 50px;
  text-align: center;
  align-items: center;
  justify-content: center;
}

span.progress-as-text select {
  font-size: 12px;
}

.teams-people {
  padding: 0 15px 0;
}

.teams-people-inner {
  font-size: 13px;
  margin: 12px 0;
  color: #5e5c5c;
  letter-spacing: 0.24px;
  cursor: pointer;
}

.teams-people-scroll {
  height: 400px;
  overflow: auto;
}

// css by deepak

.mvp_table .tdoksnames {
  cursor: pointer;
  color: #3b7aad;
  font-size: 15px !important;
  line-height: 19px;
  font-weight: 600;
}

.okrs_tabsul {
  // background: #fff;
  padding: 3px;
  border-radius: 8px 8px 0 0;
  display: flex;
  justify-content: space-between !important;
}
.okrs_tabsul .nav-pills .nav-link.active,
.okrs_tabsul .nav-pills .show > .nav-link {
  color: #fff;
  background-color: #00288a;
}

.okrs_tabsul .nav-link {
  color: #000;
  border-radius: 8px;
  font-size: 19px;
  display: flex;
  align-items: center;
  padding: 6px 10px;
}
.okrs_tabsul .nav-link svg {
  margin-right: 4px;
}
.badges_primary {
  background-color: #00208c;
  padding: 4px 10px;
  border-radius: 30px;
  color: #fff;
  font-size: 10px;
  text-transform: uppercase;
}

.spacing_equal {
  padding: 0px 10px;
  text-align: center;
}

.fs12 {
  font-size: 12px;
}

.p-80 {
  padding: 80px 0px;
}

.card_line {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: white;
}

.fs14 {
  font-size: 14px;
}

.rects_lines {
  background-color: rgb(53, 53, 53);
  padding: 5px;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.arrow_absolute {
  position: absolute;
  left: -60px;
  cursor: pointer;
}

.mrleft20 {
  margin-left: 20px;
}

// .jusendokrs {
//   max-width: 700px;
//   margin-left: auto;
//   > div{
//     max-width: 20% !important;
//   }
// }

.small-text {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #617294;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

ul.detail-view-list {
  margin: 0;
  padding: 0;
}

.detail-view-list > li {
  display: flex;
}
// .custom-select-btn .css-1nmdiq5-menu{
//   overflow-y: auto !important;
//   height: 100px !important;
// }
.dropdowns_custom .dotted {
  transform: rotate(90deg);
}

.multiSelectContainer li {
  font-size: 14px;
}
.multiSelectContainer li:hover {
  background: #f2f2f2 !important;
  color: #2a2626 !important;
  cursor: pointer;
}
.optionListContainer {
  z-index: 3 !important;
}
.create-avatar-name > div {
  width: 24px !important;
  height: 24px !important;
  font-size: 10px !important;
  line-height: 24px !important;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 3px;
  background-color: #00218f !important;
}
.grey-color {
  color: #929395;
}
.paginationWrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 40px;
  margin-top: 20px;
}
.record-number {
  font-size: 13px;
}

.react-pagination-js-bootstrap .page {
  color: #585858;
}
.billing_box h3 {
  font-size: 17px;
}
.billing_box h3 img {
  margin-right: 4px;
}
.change-password-page label {
  font-size: 14px;
  font-weight: 500;
}
.mobile-number-set input {
  border: 0;
  background: transparent;
}
.mobile-number-set input {
  border: 0;
  background: transparent;
  width: 205px;
}
.mobile-number-set input:focus {
  background: transparent;
}
.mobile-no-main .react-tel-input .form-control {
  width: 240px;
}
.profile-btn-label {
  position: absolute;
  left: 52px;
  background: #ecf1f6;
  padding: 4px !important;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  color: #685e5e;
  border: 1px solid #efefef;
  border-radius: 50%;
  cursor: pointer;
}
.profile-btn-label:hover {
  position: absolute;
  left: 52px;
  background: #ecf1f6;
  padding: 4px !important;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 0;
  color: #685e5e !important;
  border: 1px solid #efefef;
}
.multitree-box .css-bj323c-MuiTreeView-root {
  overflow-x: unset;
}
.company-name-title h5 {
  font-size: 14px !important;
}
.btn-text {
  font-size: 13px;
  padding: 6px 20px;
  color: #0f149f;
}
.btn-text:hover {
  background: #f2f2f2;
  border-radius: 20px;
  color: #222 !important;
  padding: 6px 20px;
}
.domain-width .small-text {
  text-overflow: unset;
  overflow: unset;
  white-space: unset;
  line-height: 20px;
}
.domain-width {
  width: 43%;
}
.company-name > h5 {
  color: #2c2c2c;
  font-weight: 400;
  font-size: 17px;
}
.price-plans {
  border: 1px solid #efefef;
  padding: 16px 12px 16px;
  border-radius: 14px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  position: relative;
}
.plan-title {
  font-size: 15px;
  padding: 10px 0 14px 0;
  border-bottom: 1px solid #efefef;
}
.package-price {
  display: flex;
  justify-content: flex-start !important;
  padding: 14px 0 0 0;
  align-items: flex-end;
}
.package-price h2 {
  font-size: 30px;
  font-weight: 600;
}
.delete-text p {
  text-decoration: line-through;
  font-size: 11px;
  color: #606060;
  line-height: 10px;
}
.delete-text span {
  font-size: 11px;
  color: #606060;
  line-height: 10px;
}
.delete-text {
  line-height: 14px;
}
.quantity {
  display: flex;
  border: 1px solid #cacbcd;
  border-radius: 4px;
  overflow: hidden;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.quantity button {
  background-color: #f1f1f1;
  color: #6a6a6a;
  border: none;
  cursor: pointer;
  font-size: 20px;
  width: 41px;
  height: auto;
  text-align: center;
  transition: background-color 0.2s;
}

.quantity button:hover {
  background-color: #e2e9ff;
}

.price-plans .input-box {
  width: 80px;
  text-align: center;
  border: none;
  padding: 4px 10px;
  font-size: 15px;
  outline: none;
  resize: none;
}

.price-plans .input-box::-webkit-inner-spin-button,
.price-plans .input-box::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

// .price-plans .input-box[type="number"] {
//   -moz-appearance: textfield;
// }
.price-plans button.btn.btn-primary {
  font-size: 12px;
  width: 100%;
  padding: 7px 10px;
}
.range-btns {
  display: flex;
  align-items: center;
  margin: 17px 0;
}
.users-text p {
  margin: 0 0 0 10px;
  font-size: 13px;
}
.email-text {
  font-size: 12px;
  color: #5e5959;
  letter-spacing: 0.45px;
  margin: 3px 0 0 0;
}
.selected-teams-main span {
  font-size: 12px;
  background: #efefef;
  padding: 3px 12px;
  border-radius: 10px;
  color: #524d4d;
  margin-right: 5px;
  margin-top: 5px;
}
.selected-teams-main {
  margin: 5px 0 0 0;
  align-items: center;
  flex-wrap: wrap;
}
.text-right-two select option:hover {
  background: #f2f2f2;
}

// .custom-select-btn {
//   overflow-y: auto !important;
//   height: 100px !important;
// }

.multiselect-custom .searchWrapper {
  display: flex;
  flex-wrap: wrap;
}
.multiselect-custom .searchWrapper .chip {
  background: #f2f2f2;
  color: #222;
}
.css-1n6sfyn-MenuList .css-d7l1ni-option {
  background: #f2f2f2 !important;
  font-size: 14px !important;
  color: #000 !important;
  cursor: pointer;
}
.css-tr4s17-option {
  background-color: transparent !important;
  color: #616060 !important;
  font-size: 14px !important;
  cursor: pointer;
}
.select-inside i {
  font-size: 12px;
}
.multiselect-custom .searchWrapper img {
  filter: brightness(0.5);
}
.css-tr4s17-option:active {
  background-color: #e9e9e9 !important;
  color: #746b6b !important;
  cursor: pointer;
}
.css-tr4s17-option:visited {
  background-color: #e9e9e9 !important;
  color: #746b6b !important;
  cursor: pointer;
}
.css-d7l1ni-option {
  background-color: #e9e9e9 !important;
}
.css-10wo9uf-option {
  font-size: 14px !important;
}
.css-1n6sfyn-MenuList {
  max-height: 175px !important;
}
.css-1jqq78o-placeholder {
  font-size: 14px !important;
}
.css-t3ipsp-control:hover {
  box-shadow: none !important ;
  border: 1px solid #00208c !important;
}
.css-1dimb5e-singleValue {
  font-size: 14px !important;
}
.css-tj5bde-Svg {
  cursor: pointer !important;
  font-size: 12px !important;
}
input.form-control {
  resize: none;
}
.border-btm-grey {
  border-bottom: 1px solid #d8dfea;
}

.okr-li button {
  padding: 9px 12px;
  border-radius: 50px;
  border: 0;
  font-size: 15px;
  margin: 12px 0 0 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: #e8ecf2;
  font-size: 13px;
  color: #333;
  letter-spacing: 0.45px;
}
.okr-li button:hover {
  color: #000;
  background: #e8ecf2;
}
.okr-li button:focus {
  box-shadow: none;
}
.okr-li ul li {
  padding: 4px 14px;
  border-radius: 50px;
  margin: 9px 0;
  display: flex;
  align-items: center;
}
.okr-li ul li:hover {
  background: #e8ecf2;
}
.okr-li {
  padding-bottom: 20px !important;
}
// .okr-li .btn svg {
//   transform: rotate(90deg);
// }
.okr-li .btn.collapsed svg {
  transform: rotate(90deg);
}
.okr-li ul li svg {
  background: #818c9d;
  color: #fff;
  margin-right: 6px;
  padding: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.titled_text_fifth .bg-whites {
  margin: 0 0 30px 0 !important;
}
.select-width {
  width: 200px;
}
.modal-30w {
  max-width: 400px;
}
.text-small {
  font-size: 20px;
}
.okrs_tabsul .nav-item {
  width: 33.3%;
  justify-content: center;
  align-items: center;
}
.okrs_tabsul .nav-item button {
  width: 100%;
  justify-content: center;
  text-align: center;
  border: 0;
}
.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range,
.react-datepicker__year-text--selected,
.react-datepicker__year-text--in-selecting-range,
.react-datepicker__year-text--in-range {
  border-radius: 0.3rem;
  background-color: #00218f;
  color: #fff;
}
span.monthly-txt {
  margin-left: 2px;
  margin-top: -1px;
  line-height: 30px;
  font-size: 12px;
}

.sessions-page svg {
  font-size: 19px;
}
.calendar-dropdown span i {
  color: #84818a !important;
}
.owners-number span {
  background: #002e8b;
  padding: 4px 6px;
  color: #fff;
  text-transform: capitalize;
  display: inline-block;
  font-size: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  border-radius: 4px;
}
.owners-number span svg {
  color: #fff;
  font-size: 14px;
}
.owners-number {
  display: flex;
}
.key-result-main {
  border-bottom: 1px solid #efefef;
}
.key-result-main:last-child {
  border: 0;
}
.key-result-main label {
  color: #405d75;
  font-size: 12px;
}
i.fa.fa-ellipsis-v {
  color: #808080 !important;
}
.dot::before {
  border-radius: 50%;
}
.seats-dropdown .main-seats {
  border: 1px dashed #b4babf;
  border-radius: 4px;
  font-size: 14px;
  width: 350px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #807e7e;
}
.seats-dropdown .dropdown-items {
  display: flex;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
  color: #333333;
  padding: 7px 10px;
  width: 50%;
}
.seats-dropdown .dropdown-items svg {
  margin-right: 5px;
  color: #a09595;
}
// .seats-dropdown .dropdown-items.border-right{
//   border-right: 1px dashed #b4babf !important;
// }

.seats-dropdown .btn:focus {
  box-shadow: none;
}
.select-width {
  color: #807e7e;
  font-size: 14px;
}
.select-width option {
  font-size: 13px;
  color: #333333;
}
span.hightlight-seats {
  background: #999292;
  padding: 3px;
  border-radius: 50%;
  width: 20px;
  height: 19px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  color: #fff;
}
.features-main {
  display: flex;
}
.features-tick-mark {
  display: flex;
}

.features-display {
  border: 1px solid #efefef;
}

.table-feature-head {
  padding: 10px;
  border-bottom: 1px solid #efefef;
  font-size: 12px;
  color: #333;
  letter-spacing: 0.45px;
  font-weight: 500;
}
.features-display .col-md-7 .row .col-md-4:last-child {
  padding-right: 15px !important;
}
.scrollbar-height-set {
  overflow-y: auto;
  height: 500px;
  overflow-x: hidden;
  padding: 0 10px;
}

.employee-status .badge.badge-success {
  font-size: 12px !important;
  font-weight: 400;
  cursor: pointer;
  width: 90px;
  background: #5abd71 !important;
}

.common-icon svg {
  background: #9ba1ab;
  color: #fff;
  margin-right: 6px;
  padding: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.employee-tabs .nav-link.active svg path {
  stroke: #fff;
}
// .css-1bcfi89-MuiTreeItem-content.Mui-selected{
//   background: transparent !important;
// }
.css-1bcfi89-MuiTreeItem-content .MuiTreeItem-iconContainer svg {
  font-size: 18px;
  z-index: 99 !important;
}
.circle-arrow {
  position: relative;
  z-index: 2 !important;
}
.imgs_circle {
  justify-content: flex-end;
}
.recommended p {
  margin: 0;
  text-align: center;

  border-radius: 10px;
  background: #00208c;
  color: #fff;
}
.active_card {
  background-color: #e8ecf2;
  color: #403e3e !important;

  border: 1px solid #d0cece;
}
.active_card .text_primary {
  color: #323232 !important;
}

.active_card .text-muted {
  color: #736969 !important;
}

.special-text {
  position: relative;
  cursor: pointer;
  width: 200px;
  margin: 0 auto;
  top: 10px;
}

.special-text__tooltip {
  font-size: 14px;
  position: absolute;
  width: 100%;
  left: 0%;
  bottom: 100%;
  margin-bottom: 15px;
  padding: 7px 10px;
}
.main-plan {
  padding-top: 40px !important;
}

.special-text__tooltip::before,
.special-text__tooltip::after {
  content: "";
  position: absolute;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  top: 100%;
  left: 50%;
  margin-left: -10px;
}

.special-text__tooltip::before {
  border-top: 10px solid #001f89;
}

.special-text__tooltip::after {
  margin-top: -2px;
  z-index: 1;
}
.margin20 {
  margin-top: 70px;
}
.ribbon {
  position: absolute;
  top: -7px;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  align-items: center;
  justify-content: center;
}

.ribbon img {
  width: 162px !important;
  max-width: unset;
  height: 28px;
}
.ribbon span {
  position: absolute;
  color: #000;
  top: 3px;
  font-size: 13px;
  letter-spacing: 0.24px;
  font-weight: 500;
}
.objective-img {
  background: #d1ebfe;
  padding: 2px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.key-img {
  background: #e6e5d6;
  padding: 2px;
  border-radius: 2px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.key-img img {
  font-size: 12px;
}
.modal-80w {
  max-width: 800px !important;
}

.mvp-table-modal table {
  width: 1200px;
}
.top_data_showing .active_li {
  padding: 5px 2px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
}
.ulclasssides li .aclass:hover {
  background: #fff;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  transition: 0.3s ease-out;
  display: block;
}
.ulclasssides li .aclass:hover svg {
  color: #000;
}
.small_sdies .ulclasssides {
  padding: 0px;
  list-style: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.upgrade-plan {
  padding: 10px 15px 10px;
  text-align: center;
  cursor: pointer;
  font-size: 14px;
}
.upgrade-plan span:hover {
  background: #efefef;
}
.upgrade-plan span {
  border: 1px solid #001f89;
  padding: 7px 12px;
  border-radius: 50px;
}
.ulclasssides span {
  line-height: 20px;
}
.cancel-plan {
  padding: 0;
  font-size: 13px;
  bottom: 45px;
  color: #ce1a1a;
  border: 0;
}
.titled_text_fifth .bg-whites {
  padding: 41px !important;
}
.cancel-plan:focus {
  box-shadow: none;
}
.cancel-plan:active {
  border: 0;
  outline: 0;
}
.cancel-plan:hover {
  color: #ce1a1a !important;
}
.cancel-plan-modal img {
  width: 115px;
  margin: 0 auto;
}
.cancel-plan-modal h4 {
  text-align: center;
  margin: 22px 0;
  line-height: 32px;
  font-size: 21px;
}
.cancel-plan-modal button {
  margin: 0 auto;
  display: block;
  width: 100px;
}
.cancel-plan-modal {
  padding: 0 20px 20px;
}
.features-display {
  border: 1px solid #efefef;
  display: flex;
  overflow: auto;
  height: 400px;
  background: #fff;
}
.feature-inside-one {
  width: 40%;
}
.features-inside {
  display: flex;
  width: 100%;
}
.feature-display-right {
  width: 58%;
}
.feature-display-left {
  width: 42%;
  font-size: 14px;
}
.table-feature-head-left {
  width: 42%;
}
.table-feature-head-right .text-capitalize {
  font-size: 14px;
}
.table-feature-head-right {
  width: 58%;
  display: flex;
}
.feature-main {
  background: #efefef;
  padding: 20px;
}
.feature-main.feature-m-modal {
  background: #efefef;
  padding: 20px;
  position: sticky;
  top: 0;
  z-index: 99;
  margin: 0;
}
.scrollbar-height-set .features-display {
  overflow: unset;
  height: unset;
}
.mvp_table .fa.fa-pen.me-2 {
  color: #827a7a;
}
.confidence-btns {
  background: #efefef;
  padding: 3px;
  border-radius: 8px;
  display: flex;
  justify-content: space-between !important;
}
.confidence-btns li {
  list-style: none;
  width: 33.3%;
}
.confidence-btns li button {
  width: 100%;
  border-radius: 4px;
  padding: 6px 12px !important;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
}
ul.confidence-btns svg {
  font-size: 16px;
}
.confidence-btns li button:focus {
  box-shadow: none;
}
.confidence-btns button.btn.light-yello-btn.mr-2 {
  background-color: transparent;
  font-size: 12px;
}
.confidence-btns button.btn.light-yello-btn:hover {
  color: #000;
}
.cancel-plan:focus {
  outline: 0;
  box-shadow: none;
  border: 0;
}
.btn-check:checked + .btn:focus-visible,
.btn.active:focus-visible,
.btn.show:focus-visible,
.btn:first-child:active:focus-visible,
:not(.btn-check) + .btn:active:focus-visible {
  box-shadow: none;
}
.cancel-plan:focus:not(:focus-visible) {
  outline: 0;
  box-shadow: none;
  border: 0;
}
.no-plan {
  text-align: center;
}
.no-plan img {
  width: 200px;
  margin: 0 auto;
}
.mvp_table.mvp-table-modal table td:first-child {
  width: unset;
}
.mvp_table.mvp-table-modal table th {
  font-size: 13px;
  color: #484a4e;
  font-weight: 400;
  border-bottom: 1px solid #dee2e6 !important;
  background: #efefef;
}
.employee-status .badge.badge-success {
  font-size: 12px !important;
  font-weight: 400;
  cursor: pointer;
  width: 90px;
  background: #5abd71 !important;
  border-radius: 5px;
  padding: 6px 10px;
  color: #fff;
  border: 0;
}
.employee-status .badge-danger {
  font-size: 12px !important;
  font-weight: 400;
  color: #fff !important;
  cursor: pointer;
  width: 90px;
  background-color: #dc3545 !important;
  border-radius: 5px;
  padding: 6px 10px;
}
.payment-modal i.fa.fa-download {
  color: #8b8b8b;
}
// .payment-modal i.fa.fa-download {
//   color: #8b8b8b;
// }

// Css by manjinder
// Employees page
.new_seats {
  font-size: 9px;
  display: flex;
  justify-content: end;
  color: green;
  margin-top: 5px;
  margin-right: 10px;
}

// sidebar
.side_title {
  text-transform: capitalize;
}

// Signup page
.right_hight .right_side {
  height: auto !important;
}

// invoice
h4.current_title {
  margin-bottom: 0px;
  font-size: 18px;
  background: #80808030;
  padding: 15px;
}
.invoice_download {
  background: #efefef;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 9px;
  font-size: 12px;
}
.invoice_mod_label {
  font-size: 14px;
  width: 180px;
}
.invoice_download:hover {
  background: #fff;
}
.invoice_mod_data {
  font-size: 13px;
  color: #484a4e;
  font-weight: 400;
}
.invoice_card {
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  width: 70%;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  margin: auto;
}
.span_s {
  font-size: 10px;
}
.objective-tbl-main thead th:nth-child(1) {
  width: 15% !important;
}
.objective-tbl-main thead th:nth-child(2) {
  width: 5% !important;
}
.objective-tbl-main thead th:nth-child(3) {
  width: 50% !important;
}
.objective-tbl-main thead th:nth-child(4) {
  width: 13% !important;
  text-align: end;
}
.objective-tbl-main thead th:nth-child(5) {
  width: 12% !important;
  text-align: end;
}
.objective-tbl-main thead th:nth-child(5) {
  width: 5% !important;
  text-align: end;
}
.mvp_table.objective-tbl-main td {
  padding: 0.75rem;
}
.name_s {
  display: flex;
  align-items: center;
}
span.current-page {
  margin: 0 0 0 4px;
  font-size: 16px;
  color: #4f4c4c;
}
p.light-text {
  font-size: 14px;
  color: #545353;
  letter-spacing: 0.34px;
  margin: 6px 0 0 0;
}
.set-password h3 {
  font-size: 30px;
}
.green-color {
  background-color: #a9d18e !important;
  color: #333;
  font-size: 10px;
  width: 94px;
}
.red-color {
  background-color: #ff5050 !important;
  color: #333;
  font-size: 10px;
  width: 94px;
}
.orange-color {
  background-color: #ffd986 !important;
  font-size: 10px;
  width: 94px;
  color: #333;
}
span.badges-diff {
  display: flex;
  align-items: center;
}
span.badges-diff svg {
  font-size: 12px;
}
.objective-tbl-main-two td:nth-child(2) {
  width: 35%;
}
.objective-tbl-main-two td:nth-child(3) {
  padding: 0 5px;
}
.objective-tbl-main td:first-child {
  width: 15%;
}
.objective-tbl-main td:first-child {
  font-weight: 600;
  font-size: 14px;
}
.objective-tbl-main
  td:first-child
  span.tdoksnames.text-capitalize.text-ellipses {
  font-size: 14px !important;
}
.objective-tbl-main
  td:nth-child(2)
  span.tdoksnames.text-capitalize.text-ellipses {
  font-size: 15px !important;
}
.objective-tbl-main td:nth-child(2) {
  font-weight: 600;
  font-size: 15px;
  color: #3b7aad !important;
}
.objective-tbl-main td:nth-child(2) {
  width: 5%;
}
.objective-tbl-main td:nth-child(3) {
  width: 50%;
}
.objective-tbl-main td:nth-child(4) {
  width: 13%;
}
.objective-tbl-main td:nth-child(5) {
  width: 12%;
}
.objective-tbl-main td:nth-child(6) {
  width: 5%;
  text-align: end;
}
.progress-width {
  min-width: 7% !important;
  text-align: center;
}
.mvp_table .tdoksnames.text-ellipses {
  cursor: pointer;
  color: #3b7aad;
  font-size: 12px !important;
  display: -webkit-box;
  // -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
// .name_s.name-margin {
//   // margin: 0 185px 0 0;
// }
.filters-sect button {
  background: transparent !important;
  border: 1px solid #988c8c !important;
  border-radius: 10px;
  color: #000 !important;
}
.filters-sect .dropdown-menu {
  width: 500px;
}
.filters-sect .dropdown-item.active,
.filters-sect.dropdown-item:active {
  background-color: transparent;
}
.key-result-main .row {
  padding: 13px 0;
}
.key-result-main:first-child .row {
  padding-top: 0;
}
// .sales_section {
//   background: #fff;
//   padding: 32px;
//   border-radius: 12px;
//   width: 100%;
//   height: 100%;
//   max-height: 125px;
//   box-shadow: 0px 0px 2px #cccccc40 !important;
// }
// h3.Amount_sales {
//   font-size: 24px;
//   font-weight: 700;
//   line-height: 32px;
//   margin-bottom: 0px !important;
// }

.circle-arrow svg {
  background: #00208c;
  position: relative;
  padding: 5px;
  border-radius: 50%;
  width: 22px;
  height: 22px;
  color: #ffffff;
}
.MuiTreeItem-iconContainer svg {
  background: #00208c;
  opacity: 1;
  color: #fff;
  border: 0;
  outline: 0;
  padding: 3px;
  border-radius: 50%;
  width: 20px !important;
  height: 20px !important;
  z-index: 99 !important;
}
.MuiTreeItem-iconContainer {
  width: 20px !important;
}
.filter-dropdown .dropdown-menu {
  width: 400px;
}
.border-btn {
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  padding: 7px 20px;
  color: #405d75;
}
.border-btn:hover {
  color: #405d75 !important;
  border: 1px solid rgba(0, 0, 0, 0.15) !important;
}
.border-btn:focus {
  box-shadow: none;
}
.filter-dropdown .dropdown-item.active,
.filter-dropdown .dropdown-item:active {
  background-color: transparent;
}
.filter-dropdown .dropdown-item:hover {
  background-color: transparent;
}
.filter-dropdown {
  display: flex;
}
.mvp_table table td span.badges-diff {
  font-size: 10px !important;
}

.clear-btn button.btn.btn-danger {
  border-radius: 4px !important;
  padding: 5px 10px;
  font-size: 13px;
  display: flex;
  align-items: center;
}
.filter-dropdown .dropdown-menu {
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
}
.dropdown-item label {
  color: #6c6b6b;
  font-size: 15px;
  width: 110px;
  margin: 0;
}
.grey-text {
  font-size: 12px;
  color: #bababa !important;
  margin: 5px 0 0 0 !important;
}
.card-head-height {
  height: 60px;
}
.left-days {
  font-size: 11px;
}
.no-seat-modal svg {
  width: 88px;
  display: block;
  margin: 0 auto 20px;
  height: 88px;
  background: #efefef;
  border-radius: 11px;
  padding: 20px;
  filter: opacity(0.6);
}
.ok-btn {
  border-radius: 5px !important;
  width: 90px;
}
.no-data img {
  width: 75px;
  margin: 0 auto 18px auto;
}
.drk-big-btn {
  width: 280px;
}
.blue-border-btn {
  border: 1px solid #00208c !important;
  color: #00208c !important;
  margin-top: 30px !important;
  width: 196px;
}
section.eight-sect {
  background: #f1f2fe;
  padding-bottom: 50px;
}
.faq-sect {
  background: #27262b;
  border-radius: 30px;
  padding: 30px;
}
.faq-inner .card-header button {
  color: #fff;
  border: 0;
}
.faq-inner .card {
  background: #1b1b1b;
  border: 0;
  margin: 0 0 20px 0;
}
.faq-inner .card-body {
  background: #1b1b1b;
  color: #95959d;
  font-size: 12px;
  text-align: left;
}
.faq-inner .accordian {
  border-radius: 10px;
}
.faq-inner .card h2 button {
  font-size: 14px !important;
  color: #e2e2e2 !important;
  text-decoration: none;
  padding-left: 0;
}
.faq-inner .card h2 {
  color: #e2e2e2 !important;
}
.faq-left h2 {
  color: #fff;
  text-align: left;
  width: 200px;
  line-height: 52px;
  font-size: 37px;
}
.faq-left .btn-primary {
  margin: 40px 0px 0px 0;
  display: block;
}
.ques-box {
  background: #1b1b1b;
  margin: 40px 0 0 0;
  padding: 28px;
  border-radius: 14px;
  text-align: left;
}
.ques-box h4 {
  color: #fff;
}
.ques-box a {
  color: #fff;
  margin: 20px 0 0 0;
  display: block;
}
.ques-box a span {
  color: #7f8ebe;
  margin: 0 10px 0 0;
}
.multitree-box .css-9a1slr {
  overflow: unset !important;
}
.scrollulclass {
  height: 320px;
  overflow-y: auto;
}
.mamnger-icon.common-icon svg {
  background: #73788a;
  color: #fff;
  margin-right: 6px;
  padding: 2px;
  width: 18px;
  height: 18px;
  border-radius: 50%;
}
.alings_data {
  font-weight: 600;
}
.height-40 {
  height: 40px;
}

/* about us css */
section.about_us {
  margin: 100px 0px;
  position: relative;
}
section.about_us::before {
  content: "";
  position: absolute;
  top: -52px;
  left: -30px;
  width: 33%;
  height: 509px;
  background: rgba(60, 60, 171, 1);
  opacity: 8%;
}

.image_one {
  width: 100%;
  object-fit: cover;
  max-width: 512px;
  height: 100%;
}

.title_aboutUs {
  color: rgba(60, 60, 171, 1);
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 0px;
}

.title_services {
  color: rgba(26, 26, 26, 1);
  font-size: 36px;
  text-transform: capitalize;
  font-weight: 500;
}

.ParagrpH {
  font-size: 14px;
  font-weight: 400;
  color: rgba(0, 0, 0, 1);
  margin-top: 20px;
}
.banner_aboutus {
  position: relative;
  max-width: 463px;
  margin-left: auto;
  padding-left: 21px;
}

.banner_aboutus::before {
  content: "";
  position: absolute;
  top: -86px;
  left: 0px;
  width: 100px;
  height: 164px;
  background: rgba(60, 60, 171, 1);
  opacity: 8%;
}

/* start welcome section  css */

.title_welecome {
  color: rgba(26, 26, 26, 1);
  font-size: 41px;
  font-weight: 500;
  text-transform: capitalize;
  max-width: 391px;
  padding-bottom: 23px;
}

.welcome_names {
  color: rgba(26, 26, 26, 1);
  font-size: 24px;
  font-weight: 400;
  margin-bottom: 0px;
  margin-top: 34px;
}
.aboutUs_welcome {
  margin-top: 11px;
}
.padding_welcome {
  padding-right: 54px;
}
.welcome,
.team_work,
.financesbx,
.accomplish,
.growing_bx {
  margin-bottom: 150px !important;
}

/* start team section css */

.team_work_relative {
  position: relative;
  padding-right: 65px;
}

.team_work_relative::before {
  content: "";
  position: absolute;
  top: -65px;
  left: -14px;
  width: 100px;
  height: 202px;
  background: rgba(60, 60, 171, 1);
  opacity: 8%;
}

/* start finances css  */
.finances_title {
  color: rgba(26, 26, 26, 1);
  font-size: 35px;
  text-transform: capitalize;
  font-weight: 400;
  margin: 0px;
  max-width: 425px;
}

.small_paragraph {
  color: rgba(0, 0, 0, 1);
  font-size: 13px;
  font-weight: 400;
  max-width: 384px;
  margin: 0px;
}

.orange_bx {
  background: rgba(217, 60, 32, 1);
  width: 189px;
  padding: 45px;
  height: 189px;
  display: flex;
  margin: 0px auto;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}
.orange_main {
  text-align: center;
  max-width: 227px;
  margin: 0px auto;
}
.icons_title {
  font-weight: 400;
  font-size: 20px;
  color: rgba(26, 26, 26, 1);
}
.img_icons {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.space_finances {
  margin-bottom: 48px;
}
.financesbx {
  position: relative;
}

.financesbx::before {
  content: "";
  position: absolute;
  top: -15px;
  left: -46px;
  width: 31%;
  height: 75px;
  background: rgba(60, 60, 171, 1);
  opacity: 8%;
}

/* start accomplish css  */

img.img_icons {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.accomplish .title {
  font-size: 35px;
  font-weight: 400;
  color: rgba(255, 255, 255, 1);
  text-transform: capitalize;
}
.accomplish {
  background-image: url("../../public/assets/img/accomplish_banner.png");
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 80px 2px;
}

// .btn-primary {
//   background: rgba(0, 33, 143, 1);
//   padding: 9px 83px;
//   font-weight: 400;
//   border-radius: 0px;
//   font-size: 14px;
// }
.aboutus-text p {
  text-align: center !important;
  margin: 0 auto;
  display: block;
}
.accomplish_para {
  font-size: 14px;
  font-weight: 400;
  margin: 24px auto;
  color: #fff;
  max-width: 673px;
}

/* css start growing  */

.growing_bx {
  position: relative;
}

.growing_bx::before {
  content: "";
  position: absolute;
  top: 65px;
  left: -46px;
  width: 25%;
  height: 95px;
  background: rgba(60, 60, 171, 1);
  opacity: 8%;
}

.image_two {
  width: 100%;
  height: 100%;
  object-fit: cover;
  max-width: 404px;
}

.growing_img {
  text-align: center;
}

.aboutus-title .hero-wrap-text {
  margin-top: 155px;
}
.profile-after::after {
  display: none !important;
}
/* responsive css */
@media (max-width: 992px) {
  .orange_main {
    max-width: 160px;
    margin: 0px auto;
    margin-bottom: 20px;
  }
  section.about_us::before {
    display: none;
  }

  .banner_aboutus::before {
    display: none;
  }

  .title_services {
    font-size: 24px;
  }

  .image_one {
    max-width: 428px;
  }

  .title_welecome {
    font-size: 24px;
  }

  .ParagrpH {
    font-size: 13px;
  }

  .welcome_names {
    margin-top: 12px;
  }

  .team_work_relative {
    padding-right: 0px;
  }

  .team_work_relative::before {
    display: none;
  }

  .finances_title {
    font-size: 24px;
    max-width: 327px;
  }

  .financesbx::before {
    display: none;
  }

  .small_paragraph {
    font-size: 12px;
    max-width: 355px;
  }

  .orange_bx {
    max-width: 161px;
    height: 161px;
    padding: 40px;
    margin-bottom: 10px;
  }

  .icons_title {
    font-size: 16px;
  }

  .accomplish .title {
    font-size: 24px;
  }

  .accomplish_para {
    font-size: 12px;
  }

  .growing_bx::before {
    display: none;
  }

  .image_two {
    height: 400px;
  }
  section.about_us {
    margin: 80px 0px;
  }

  .welcome,
  .team_work,
  .financesbx,
  .accomplish,
  .growing_bx {
    margin-bottom: 80px !important;
  }
}

@media (max-width: 768px) {
  .image_one {
    max-width: 76%;
    height: 100%;
    object-fit: contain;
    margin-bottom: 20px;
  }

  .banner_aboutus {
    max-width: unset;
    padding-left: 0px;
  }

  .padding_welcome {
    padding-right: 0px;
  }

  .aboutUs {
    text-align: left;
  }

  .welcome_names {
    font-size: 20px;
  }

  .finances_title {
    font-size: 24px;
    max-width: 100%;
    text-align: center;
  }

  .small_paragraph {
    font-size: 12px;
    max-width: 100%;
    text-align: center;
  }

  .image_two {
    height: 337px;
    max-width: 100%;
    object-fit: contain;
    margin-bottom: 30px;
  }

  .ParagrpH {
    font-size: 14px;
    margin-top: 10px;
  }
}

@media (max-width: 576px) {
  .image_one {
    max-width: 100%;
    object-fit: cover;
  }

  .title_aboutUs {
    margin-bottom: 8px;
  }

  .title_services {
    font-size: 20px;
  }

  .ParagrpH {
    font-size: 13px;
  }

  .title_welecome {
    font-size: 20px;
    padding-bottom: 10px;
  }

  .aboutUs_welcome {
    margin-bottom: 11px;
  }

  .welcome_names {
    font-size: 16px;
  }

  .team_img_bx {
    margin-top: 20px;
  }

  .finances_title {
    font-size: 20px;
  }

  .orange_bx {
    max-width: 154px;
    height: 154px;
    padding: 37px;
  }

  .accomplish .title {
    font-size: 20px;
  }

  .image_two {
    object-fit: cover;
  }
}

.scrollTable.table-responsive {
  height: 400px;
  overflow-y: auto;
}
.payment-modal .btn-primary i.fa.fa-download {
  color: #ffffff;
  margin: 0 10px 0 0 !important;
}
.height-38 {
  height: 38px;
}

.aligmentinrs .MuiTreeItem-content.Mui-expanded .circle-arrow svg {
  background: #617fe1;
}

.circle-arrow.red-colorx svg {
  background: #bb2727 !important;
}
.clock-icon img {
  width: 100px;
  margin: 0 auto;
}
.filter-btn {
  border-radius: 4px;
  font-size: 13px;
  padding: 5px 20px;
}
.success-story {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 80vh;
}
.custom-badge {
  width: 90px;
  text-align: center;
  align-items: center;
  justify-content: center;
}
.badge.badge-danger.custom-badge {
  background-color: #c80a0a !important;
  color: #ffffff !important;
}
.highlighted-name h4 span {
  margin-left: 5px;
}
.highlighted-name h4 {
  font-weight: 400;
  color: #00208c;
}
.hand img {
  width: 40px;
}
.ulclasssides.nav-tabs {
  border-bottom: 0;
}
.analytics-box p {
  background: #efefef;
  padding: 14px;
  border: 1px solid #c8c8c8;
  border-radius: 4px;
}

.mvp_table.calendar_table_main th:first-child {
  border-top-left-radius: unset !important;
}
.mvp_table.calendar_table_main th:last-child {
  border-top-right-radius: unset !important;
}
.mvp_table table th:first-child {
  border-top-left-radius: 10px;
}
.mvp_table table th:last-child {
  border-top-right-radius: 10px;
}

.mvp_table table tbody tr:last-child td:first-child {
  border-bottom-left-radius: 10px;
}
.mvp_table table tbody tr:last-child td:last-child {
  border-bottom-right-radius: 10px;
}
.mvp_table table tbody tr:last-child td {
  border-bottom: unset;
}

.calendar_table_main.mvp_table table tbody tr:last-child td:first-child {
  border-bottom-left-radius: unset !important;
}
.calendar_table_main.mvp_table table tbody tr:last-child td:last-child {
  border-bottom-right-radius: unset !important;
}
.calendar_table_main.mvp_table table tbody tr:last-child td {
  border-bottom: 1px solid #dee2e6 !important;
}
.analytics-box span b {
  text-transform: capitalize !important;
}
.active-session {
  background: #e8ecf2;
  border-radius: 50px;
  padding: 4px 14px !important;
}
.width-set {
  width: 18%;
}
.objective-owners-m {
  padding: 0;
  margin: 0;
  display: flex;
}
.objective-owners-m li {
  list-style: none;
  margin-right: 4px;
}
.objective-owners-m li:last-child .comma {
  display: none;
}
.objective-owners-m li span {
  color: #545454;
  font-weight: 400;
}
.team-n {
  font-size: 13px;
  line-height: 13px;
  font-weight: 500;
  color: #494949;
}
.circle-arrow-sbsolute.opened {
  background: #617fe1;
}
.card_line_head {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #fff;
  color: #ffffff;
}
.card_line_head h6,
.card_line_head_two h6 {
  text-transform: capitalize;
  font-size: 13px;
  color: #929395;
  font-weight: 400;
}
.card_line_head_two {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #d9d9d9;
  background: #ffffff;
  margin: 0 0 10px 28px;
  font-size: 13px;
  color: #929395;
  font-weight: 400;
}
// Css by Manjinder

.widthflex {
  width: 54%;
}
.widthflextwo {
  width: 70%;
}
.widthprotwo {
  width: 10%;
}
.copydivtwo {
  width: 20%;
}
.widthpro {
  width: 7%;
}
.last-field {
  width: 15%;
}
.copydiv {
  width: 19%;
}
.fontit {
  font-size: 12px;
  text-transform: capitalize;
}
.svgspan {
  background: #80808038;
  padding: 5px;
  margin-right: 5px;
  border-radius: 5px;
}
.svgspan svg {
  padding-bottom: 1px;
  font-size: 15px;
}
.pleft {
  padding-left: 20px;
}

.margnit {
  margin-top: 10px;
  margin-bottom: 10px;
}
.paddingleft {
  padding-left: 3rem;
}
.paddingleft2 {
  padding-left: 0rem;
}
.css-1bcfi89-MuiTreeItem-content:hover {
  background: unset !important;
}
.circle-arrow-sbsolute {
  position: absolute;
  left: -24px;
  top: 50%;
  transform: translateY(-50%);
  background: #00208c;
  color: #fff;
  border-radius: 50px;
}
.pointer.circle-arrow-sbsolute img {
  filter: invert(1);
  padding: 3px;
  width: 22px;
}
.susbidary-child .row {
  margin-top: 0 !important;
}
.sliced-desc p {
  font-size: 12px;
  color: #434343;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  // -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}
.end-lines h6 {
  text-align: end;
}
.fc .fc-view-harness {
  background: #fff !important;
}
.tabs-new {
  //background: #efefef;
  max-width: 300px;
  padding: 3px;
  border-radius: 10px;
}
.tabs-new .btn-secondary {
  background: #efefef !important;
  border: 0;
  color: #000 !important;
}
.tabs-new button.btn {
  font-size: 13px;
  line-height: 26px;
  padding: 4px;
}
.tabs-new .btn:focus {
  box-shadow: none !important;
}
ul.detail-view-list .w-60 {
  width: 59%;
  margin-left: 5px;
}
.detail-view-list li > p {
  width: 170px;
}
span.delete-point i {
  font-size: 11px;
}
.team-full-detail .detail-view-list li > p {
  width: 40% !important;
}
.team-profile.team-profile-centered {
  border-bottom: 1px solid #ebebeb;
  padding: 10px 0 25px 0;
}
.white-bg-rounded {
  border-radius: 10px;
}
.value-title h6 {
  font-size: 14px;
  letter-spacing: 0.22px;
}
.value-title {
  width: 90%;
}
.tabs-new button.btn svg {
  font-size: 16px;
}
.tabs-new button.btn {
  font-size: 13px;
  line-height: 27px;
  padding: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.multiselect-manager p {
  min-height: 38px;
  border-radius: 5px;
}
.multiselect-manager p span {
  margin: 2px 0;
  display: inline-block;
  background: #efefef;
  font-size: 12px;
  border-radius: 15px !important;
  border: 0 !important;
  padding: 3px 10px !important;
}
.overall-progress-main {
  border: 1px solid #efefef;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px;
  border-radius: 4px;
}
.reasoning-border {
  border: 1px solid #efefef;
  padding: 10px;
  margin: 0 !important;
  border-radius: 4px;
}
.reasoning-border label {
  margin: 0;
}
.width-calc {
  width: calc(100% - 280px);
}
.width-25 {
  width: 40%;
}
.width-75 {
  width: 58%;
}
.align-width {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.width-24 {
  width: 40%;
}
.width-74 {
  width: 58%;
}
.key-area .key-result-main:first-child .margnit {
  margin-top: 0;
}
.btn-small {
  padding: 8px 14px !important;
  font-size: 13px;
}
.object-tb.key-result-table td {
  vertical-align: middle;
}
.owner-no-main span {
  margin: 4px 4px 0 0;
}
.owner-no-main {
  display: flex;
  align-items: center;
}
.menu-design .dropdown-item {
  font-size: 12px;
}
.new-ellipses {
  font-size: 12px;
}
.dropdown-item:active i {
  color: #fff !important;
}
.react-pagination-js-default .is-active {
  background-color: #002e8b;
  box-shadow: none !important;
  padding: 4px 13px;
}
.react-pagination-js-default .page {
  display: inline-block;
  padding: 4px 13px;
  background: #cfecf0;
  box-shadow: none !important;
}
.bold-txt {
  font-weight: 600;
  color: #000000b5 !important;
}
.progress-color {
  background: #3e98c7 !important;
}
.progress-bar-color {
  background: #3e98c7 !important;
}
.obj-tbl td:nth-child(3) {
  width: 65% !important;
}
.obj-tbl thead th:nth-child(3) {
  width: 65% !important;
}
.obj-tbl td:nth-child(4) {
  width: 5% !important;
  text-align: end;
  padding-right: 25px;
}
.obj-tbl thead th:nth-child(4) {
  width: 5% !important;
  text-align: end;
  padding-right: 25px;
}
.obj-tbl .copydiv {
  width: 37%;
}
.obj-tbl .progress-width {
  min-width: 4% !important;
}
.css-1bcfi89-MuiTreeItem-content {
  padding: 0 0px !important;
}
.members-images {
  font-size: 12px;
  font-family: "Be Vietnam Pro", sans-serif;
}
.free-t-text {
  text-align: center;
  font-size: 15px;
  margin-bottom: 2px;
}
.free-t-text span {
  display: block;
  font-size: 11px;
  color: #00000085;
}
.alert-info {
  color: #00208c;
  border: 0;
  width: max-content;
  background: #fff;
  padding: 10px 4px 10px 10px;
}
.unbderline {
  text-align: center;
  background: #d1ecf1;
  padding: 7px;
  margin-left: 4px;
  border-radius: 3px;
  color: #4b4b4b;
  text-transform: capitalize;
  border: 1px solid #bee5eb;
}
.right-0 {
  right: 0 !important;
}
.bgdata_ofs i.fa.fa-edit {
  color: #00000069;
  background: #fff;
  padding: 2px;
  border-radius: 50px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  width: 20px;
  height: 20px;
  font-size: 11px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: -3px !important;
}
.common-w {
  width: 10%;
  padding: 0px 5px;
}

.common-w.owners {
  width: 23%;
}
.common-w.contributors {
  width: 23%;
}
.common-w.confidence {
  width: 20%;
}
.common-w.last-updates {
  width: 11%;
  font-size: 12px;
  text-align: end;
}
.css-tc4u2w {
  padding: 0 !important;
}
p.fs-14 {
  font-size: 11px !important;
}
.css-12a83d4-MultiValueRemove:hover {
  background-color: #00208c00 !important;
  color: #302d2d !important;
}
.multiselect-new span {
  font-size: 12px;
  font-weight: 400;
  background: #f2f2f2;
  border: 0 !important;
  padding: 1px 9px !important;
  border-radius: 14px !important;
}
.team-detail-view p {
  font-size: 12px !important;
}
.height-manage {
  height: 35px !important;
}
// .calendar-n .fc .fc-toolbar-title {
//   font-size: 1.75em;
//   margin: 0px;
//   display: none;
// }

.calendar-n .fc .fc-toolbar-title {
  font-size: 16px;
  margin: 0px;
  background: #00208c;
  color: #fff;
  padding: 11px 16px;
  border-radius: 5px;
}
.calendar-n .fc-toolbar-chunk button {
  text-transform: capitalize !important;
}
.height-set-head {
  height: 40px;
}
.plan-m .bg-whites {
  padding: 33px !important;
  height: 320px;
}
@media only screen and (max-width: 1399px) {
  .img_secthirds {
    margin-top: -150px;
    position: relative;
  }
}
@media only screen and (max-width: 1299px) {
  .card_line_head h6,
  .card_line_head_two h6 {
    font-size: 10px;
    text-transform: capitalize;
    letter-spacing: 0.34px;
    line-height: 15px;
  }
  .img_secthirds {
    margin-top: -150px;
    position: relative;
  }
}

@media only screen and (max-width: 1199px) {
  .copydiv {
    width: 25%;
  }
  .widthflex {
    width: 40%;
  }
  .widthflextwo {
    width: 60%;
  }
  .copydivtwo {
    width: 30%;
  }
  .img_secthirds {
    margin-top: -150px;
    position: relative;
  }
  .titleh1 {
    font-size: 46px;
    margin-bottom: 30px;
  }
  .navbar-light .navbar-toggler-icon {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='30' height='30' viewBox='0 0 30 30'%3e%3cpath stroke='rgba(100, 100, 100, 1)' strokeLinecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
    filter: invert(1);
  }
  .plan-m .bg-whites h2 {
    font-size: 22px;
  }
  .plan-m .bg-whites {
    padding: 20px !important;
    height: 311px;
  }
}
@media only screen and (max-width: 991px) {
  .profile-tabs {
    width: 100%;
  }
  .plan-m .bg-whites {
    height: fit-content;
  }
  .table-feature-head-right {
    width: 58%;
    display: flex;
    font-size: 12px;
  }
}
@media only screen and (max-width: 767px) {
  .inputFlex {
    flex-direction: column;
  }
  .inputFlex label {
    margin-bottom: 4px;
  }
  .navbar-light .navbar-toggler {
    color: #fff;
    border-color: #fff;
  }
  .navbar-light .navbar-toggler-icon {
    filter: invert(1);
  }
}

section.darkbg {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: #ffffff;
}

ul.navbar-nav.mx-auto.mb-2.mb-lg-0.navwhite {
  display: flex;
  padding: 0;
  float: none;
}

a.btn-white-border.ms-3.nav {
  margin-left: 0 !important;
  margin-right: 10px;
  padding: 10px 10px;
}

.toptitle {
  color: #0c0047 !important;
}

.clean {
  padding: 5px 10px;
  box-sizing: border-box;
  border-radius: 5px;
  border: 1px solid #e8e4fd;
  background: #f4f6fb;
  display: flex;
  text-align: left; /* Centers text horizontally */
  vertical-align: middle; /* Ensures text alignment vertically */
  min-height: 40px;
}

.hremp {
  color: #000000;
  width: 100%;
  flex-shrink: 1 !important;
}

.sliderr {
  width: 100%;
  height: 8px;
  background: #ddd;
  border-radius: 5px;
  outline: none;
  transition: background 0.3s;
  margin-bottom: 10px;
}

/* Handle styling */
.sliderr::-webkit-slider-thumb {
  -webkit-appearance: none; /* For Safari */
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #002190;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: background 0.3s;
}

.sliderr::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #002190;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

/* Hover and active states */
.sliderr:hover {
  background: #ccc;
}

.sliderr::-webkit-slider-thumb:hover {
  background: #002190;
}

.sliderr::-moz-range-thumb:hover {
  background: #002190;
}
