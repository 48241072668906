.onboarding-progress .progress {
  height: 20px; /* Adjust the height of the progress bar */
  background-color: #b3b3b3; /* Background color of the progress bar */
  border-radius: 10px; /* Rounded corners */
  overflow: hidden;
}

.onboarding-progress .progress-bar {
  background-color: #007bff; /* Color of the progress bar */
  font-weight: bold; /* Bold text */
  font-size: 14px; /* Font size */
  line-height: 20px; /* Center the text vertically */
  text-align: center; /* Center the text horizontally */
  transition: width 0.4s ease; /* Smooth transition for width change */
}
