/* Scope all styles under the unique parent class */
.privacy-page-container {
  /* General Container Styling */
  max-width: 900px;
  margin: 0 auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  color: #333;

  /* Title Styling */
  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    text-align: center;
    margin-bottom: 20px;
    color: #2c3e50;
  }

  /* Last Updated Text */
  p.privacy-last-updated {
    text-align: center;
    font-size: 1rem;
    color: #7f8c8d;
    margin-bottom: 30px;
  }

  /* Link Styling */
  a.privacy-link {
    color: #3498db;
    text-decoration: none;
    font-weight: bold;

    &:hover {
      text-decoration: underline;
      color: #2980b9;
    }
  }

  /* Section Styling */
  section.privacy-section {
    margin-bottom: 40px;

    h2.privacy-heading {
      font-size: 1.8rem;
      font-weight: bold;
      margin-bottom: 15px;
      color: #34495e;
      border-bottom: 2px solid #ecf0f1;
      padding-bottom: 5px;
    }

    p.privacy-text {
      font-size: 1rem;
      color: #555;
    }

    a.privacy-email {
      color: #e74c3c;

      &:hover {
        color: #c0392b;
        text-decoration: underline;
      }
    }
  }

  /* Table Styling */
  table.privacy-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 15px;
    font-size: 0.9rem;

    th,
    td {
      border: 1px solid #ddd;
      padding: 10px;
      text-align: left;
    }

    th {
      background-color: #f8f9fa;
      font-weight: bold;
      color: #2c3e50;
    }

    tr:nth-child(even) {
      background-color: #f9f9f9;
    }

    tr:hover {
      background-color: #f1f1f1;
    }
  }

  /* List Styling */
  ul.privacy-list {
    list-style-type: disc;
    margin-left: 20px;

    li {
      margin-bottom: 10px;
    }
  }
}
