.billing_details .billing_bx {
  margin: 20px 0px;
  font-size: 24px;
  font-weight: 600;
  display: flex;
  color: #0f149f;
  gap: 12px;
  align-items: center;
}

.billing_btn {
  display: flex;
  gap: 12px;
  align-items: center;
  border: 1px solid;
}

.billing_btn {
  border: 1px solid #c1c1c1;
  padding: 6px 12px;
  border-radius: 8px;
}

.btn_found {
  display: flex;
  align-items: center;
  gap: 12px;
}

.img_billing {
  width: 22px;
  height: 22px;
}

.btn_found {
  font-size: 14px;
  font-weight: 500;
  color: #0f149f;
}

.btn_found .order_icon {
  color: #000;
}

.billing_box {
  border: 1px solid #e0e0e0;
  height: 178px;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 30px;
  background: #fff;
}

.billing_box .detail_next {
  font-size: 12px;
  font-weight: 400;
  color: #6e6e6e;
}

.detail_years {
  font-size: 14px;
  font-weight: 600;
  color: #212529;
  margin-top: 16px;
}

.detail_years span {
  color: #6e6e6e;
}

.details_invoices .eye_icon {
  color: #6e6e6e;
}

p.details_invoices {
  font-size: 16px;
  font-weight: 400;
}
p.details_invoices:hover {
  text-decoration: underline;
}
.card-code {
  font-size: 14px;
  font-weight: 400;
  padding: 0px 12px;
}

.expiredplan {
  color: #0f149f;
  font-size: 13px;
  cursor: pointer;
}
.img30 {
  width: 40px;
  height: 40px;
}

// okrs details

.team-head-new {
  font-size: 30px;
  font-style: normal;
  font-weight: 700;
  padding-bottom: 4px;
  line-height: 36px;
  color: #0d232f;
}

.okrs_imgs {
  width: 90px;
  height: 90px;
  border-radius: 50%;
  object-fit: cover;
}
.temasheader img {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  object-fit: cover;
}
.bgdata_ofs {
  background-color: rgb(212, 212, 212);
  border-radius: 50%;
}

.width80 {
  width: 60%;
}

.right_width {
  width: 40%;
}

.colum_one {
  width: 150px;
}

.colum_two {
  width: 100px;
}

.colum_three {
  width: 250px;
}

.fs16 {
  font-size: 16px;
}

.cirls_round {
  border-radius: 50%;
  border: 1px solid #000;
  width: 10px;
  height: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  line-height: 9px;
  padding: 12px;
}

.listviews_teams {
  padding: 10px 20px;
  border-radius: 10px;
}

// .acordians_okrs_result {
//     padding: 20px;
// }

.bggray {
  background: #e5e5e5;
}

.imgperson_op {
  width: 20px;
  height: 20px;
  border-radius: 50px;
  object-fit: cover;
}

.right_width h6 {
  margin: 0;
  font-size: 12px;
  line-height: 16px;
  color: #617294;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.marginleftchild {
  margin-left: 30px;
}

.progresstsmall {
  width: 60px;
}
.listviews_teams.listview-inner {
  border: 1px solid #d8dfea;
}
.progress {
  height: 8px;
}
.detail-view-list p {
  font-size: 14px;
  color: #646464;
  letter-spacing: 0.24px;
}
.detail-view-list span {
  display: flex;
  font-size: 13px;
}
.detail-view-list span img {
  margin-right: 4px;
}
.detail-view-list > li {
  display: flex;

  margin: 34px 0;
}
.progress-inner {
  box-shadow: 0px 1px 10px rgba(33, 76, 107, 0.2),
    0px 0px 1px rgba(63, 87, 110, 0.1);
  border-radius: 7px;
  padding: 15px;
  margin-top: 20px;
}
.progress-inner h5 {
  font-size: 15px;
  margin: 0 0 0 10px;
}
.team-percentage {
  font-size: 27px;
  margin: 10px 0;
}
.border-line {
  padding: 13px 0;
  border-bottom: 1px solid #efefef;
  border-top: 1px solid #efefef;
}
.white-card {
  box-shadow: 0px 1px 10px rgba(33, 76, 107, 0.2),
    0px 0px 1px rgba(63, 87, 110, 0.1);
  border-radius: 7px;
  padding: 15px;
}
.key-results-inner p {
  font-size: 14px;
  font-weight: 600;
}
.bottom_data_showing ul li a svg {
  color: #00218f;
}

.pagination {
  display: flex;
  width: 50%;
  padding: 2px;
  list-style: none;
}

.pagination a {
  margin: 5px;
  padding: 10px;
  border: 1px black solid;
  background-color: #05baec;
  font-weight: 1000;
}

.recent-padding {
  padding: 10px 15px 10px;
  font-size: 14px;
}
p.notes {
  font-size: 11px;
  color: #5e5e5e;
  text-align: end;
  margin-top: 2px;
  position: absolute;
  top: 8px;
  right: 8px;
}

.info-item {
  display: flex;
  //justify-content: space-between;
  margin-bottom: 10px;
}

.info-label {
  font-weight: bold;
  color: #333;
}

.info-value {
  color: #555;
}

.text-left {
  font-weight: bold;
  font-size: 1.2em;
}

.small-text {
  font-size: 0.9em;
  color: #666;
}

.company-name a {
  font-size: 1.1em;
  color: #007bff;
  text-decoration: none;
}

.company-name a:hover {
  text-decoration: underline;
}

.mt-4 {
  margin-top: 1.5rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.img-fluid {
  max-width: 100%;
  height: auto;
  border: 1px solid #ddd;
  padding: 5px;
  background-color: #f9f9f9;
}
